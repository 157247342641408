import React, { ReactNode } from 'react';
import { useTheme } from '@mui/material/styles';
import { dispatch, useAppSelector } from 'store/hooks';
import { viewsMiddleware, viewsSelector } from 'store/slices/menu';

import { ModalsController } from '../ModalsController/ModalsController';
import { ToastNotificationsController } from '../ModalsController/ToastNotificationsController';

import Footer from './Footer';
import Header from './Header';
import Sidebar from './Sidebar';
import { Avatar, Box } from '@mui/material';
import { IconMenu2 } from '@tabler/icons';

const Layout = (props: { children: ReactNode }) => {
  const theme = useTheme();
  const { children } = props;
  const { drawerOpen } = useAppSelector(viewsSelector.menu);

  return (
    <div style={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
      {/*<Header />*/}
      <div>
        <Sidebar />
        <div
          style={{
            marginLeft: drawerOpen ? '260px' : '',
            backgroundColor: theme.palette.primary.light,
            borderRadius: '8px',
            minHeight: '755px'
          }}
        >
          <div>{children}</div>
        </div>
      </div>
      <Footer />
      <ModalsController key="modals" />
      <ToastNotificationsController key="toasts" />
    </div>
  );
};

export default Layout;
