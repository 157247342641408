import React from 'react';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import RemoveIcon from '@mui/icons-material/Remove';
import { Button, Fab, Grid } from '@mui/material';

import { IPlusIconButtonProps } from '../../types/components/buttons';

export const PlusIconButton = (props: IPlusIconButtonProps) => {
  const { handlePlusButtonClick, title, error, blocks } = props;

  return (
    <Grid
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        width: '100%',
        paddingRight: '40px',
        marginTop: '20px'
      }}
      container
      direction="row"
    >
      <Grid item>
        <Fab
          style={{
            backgroundColor: '#202090',
            color: '#fff',
            width: '24px',
            height: '24px',
            minHeight: '24px',
            boxShadow: 'none'
          }}
          onClick={handlePlusButtonClick}
          size="small"
        >
          <AddRoundedIcon fontSize="small" />
        </Fab>
      </Grid>
      <Grid item sx={{ marginLeft: '8px' }}>
        <h3
          style={{ color: '#202090', fontFamily: 'Circular Std Book', fontSize: '16px', fontWeight: '400', margin: 0 }}
        >
          {title}
        </h3>
        {error && <p style={{ color: 'red', fontSize: '12px' }}>Max count is 1</p>}
      </Grid>
    </Grid>
  );
};

export const MinusIconButton = (props: { onClick: () => void }) => {
  const { onClick } = props;

  return (
    <Grid container direction="row-reverse">
      <Grid item>
        <Button className="minus-icon" onClick={onClick}>
          <RemoveIcon />
        </Button>
      </Grid>
    </Grid>
  );
};
