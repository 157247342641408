import React from 'react';

export const titleStyles: React.CSSProperties = {
  color: '#3D4047',
  fontSize: '16px',
  fontWeight: '700',
  fontFamily: 'Circular Std Book',
  paddingTop: '20px',
  paddingLeft: '30px',
  lineHeight: '20.24px'
};
