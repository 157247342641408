import React, { useEffect, useState } from 'react';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { Collapse, List, ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { IconChevronDown, IconChevronUp } from '@tabler/icons';
import { NavGroupProps } from '../NavGroup';
import NavItem from '../NavItem';

// Singleton to track the currently selected menu item
let currentSelectedMenuId: any = 'data';
const selectionChangedEvent = new Event('selectionChanged');

interface NavCollapseProps {
  menu: NavGroupProps['item'];
  level: number;
}

const NavCollapse = ({ menu, level }: NavCollapseProps) => {
  const theme = useTheme();
  const isInitialOpen = menu.id === 'data';
  const initialSelected = isInitialOpen ? menu.id : null;

  const [open, setOpen] = useState(isInitialOpen);
  const [selected, setSelected] = useState<any>(initialSelected);

  const handleClick = () => {
    const newSelectedId = selected === menu.id ? null : menu.id;

    currentSelectedMenuId = newSelectedId; // Update singleton with the new selection

    // Dispatch an event to notify all NavCollapse components of the selection change
    window.dispatchEvent(selectionChangedEvent);

    // Update local state based on new selection
    setOpen(newSelectedId === menu.id);
    setSelected(newSelectedId);
  };

  useEffect(() => {
    const handleSelectionChange = () => {
      if (currentSelectedMenuId !== menu.id && open) {
        // If this item is open but not the selected one, close it
        setOpen(false);
        setSelected(null);
      } else if (currentSelectedMenuId === menu.id) {
        // If this is the selected item, open it
        setOpen(true);
        setSelected(menu.id);
      }
    };

    // Listen for the selection change event
    window.addEventListener('selectionChanged', handleSelectionChange);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('selectionChanged', handleSelectionChange);
    };
  }, [menu.id, open]);

  const menus = menu.children?.map((item) => {
    switch (item.type) {
      case 'collapse':
        return <NavCollapse key={item.id} menu={item} level={level + 1} />;
      case 'item':
        return <NavItem key={item.id} item={item} level={level + 1} />;
      default:
        return (
          <Typography key={item.id} variant="h6" color="error" align="center">
            Menu Items Error
          </Typography>
        );
    }
  });

  const Icon = menu.icon!;
  const menuIcon = menu.icon ? (
    <Icon
      strokeWidth={1.5}
      size="1.3rem"
      style={{ marginTop: 'auto', marginBottom: 'auto', marginRight: '8px' }}
      color="black"
      width="20px"
      height="20px"
    />
  ) : (
    <FiberManualRecordIcon
      sx={{
        width: selected === menu.id ? 8 : 6,
        height: selected === menu.id ? 8 : 6
      }}
      fontSize={level > 0 ? 'inherit' : 'medium'}
    />
  );

  return (
    <>
      <ListItemButton
        sx={{
          borderRadius: `5px`,
          mb: 0.5,
          alignItems: 'flex-start',
          backgroundColor: level > 1 ? 'transparent !important' : 'inherit',
          py: level > 1 ? 1 : 1.25,
          pl: `${level * 24}px`
        }}
        selected={selected === menu.id}
        onClick={handleClick}
      >
        <ListItemIcon sx={{ my: 'auto', minWidth: !menu.icon ? 18 : 36 }}>{menuIcon}</ListItemIcon>
        <ListItemText
          primary={
            <Typography
              variant={selected === menu.id ? 'h5' : 'body1'}
              color="black"
              sx={{ my: 'auto', fontFamily: 'Circular Std Book' }}
            >
              {menu.title}
            </Typography>
          }
          secondary={
            menu.caption && (
              <Typography variant="caption" sx={{ ...theme.typography.subMenuCaption }} display="block" gutterBottom>
                {menu.caption}
              </Typography>
            )
          }
        />
        {open ? (
          <IconChevronUp stroke={1.5} size="1rem" style={{ marginTop: 'auto', marginBottom: 'auto' }} color="black" />
        ) : (
          <IconChevronDown stroke={1.5} size="1rem" style={{ marginTop: 'auto', marginBottom: 'auto' }} color="black" />
        )}
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        {open && (
          <List
            component="div"
            disablePadding
            sx={{
              position: 'relative',
              '&:after': {
                content: "''",
                position: 'absolute',
                left: '32px',
                top: 0,
                height: '100%',
                width: '1px',
                opacity: theme.palette.mode === 'dark' ? 0.2 : 1,
                background: theme.palette.mode === 'dark' ? theme.palette.dark.light : theme.palette.primary.light
              }
            }}
          >
            {menus}
          </List>
        )}
      </Collapse>
    </>
  );
};

export default NavCollapse;
