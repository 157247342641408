import API from '../../../manager/API';
import { dispatch } from '../../hooks';
import slice from './slice';
import { removeLastExtension } from '../../../helpers/removeExtension';
import { toast } from 'react-toastify';

const { setUploadData } = slice.actions;

const uploadFile = (params: any, url: any) => async () => {
  try {
    const response = await API.upload.uploadFile(params, url);
    dispatch(setUploadData(response.data));
    // toast.success('Upload successful!');
    // dispatch(resourcesMiddleware.getResources(resourcesUrl));
    return { response, status: response.status, data: response.data };
  } catch (error: any) {
    toast.error(removeLastExtension(error.response.data.message));
    return { status: error.response.status, data: error.response.data };
  }
};

export default {
  uploadFile
};
