export const checkInPureHistogram = (pureHistogram: any, columnName: any) => {
  // Check if columnName exists in the object
  if (pureHistogram && Object.keys(pureHistogram).length > 0 && pureHistogram[columnName]) {
    // Check if message_instead exists and has a length greater than 0
    if (pureHistogram[columnName].message_instead && pureHistogram[columnName].message_instead.length > 0) {
      return true;
    }
  }
  return false;
};

export const checkInCategoryFrequencies = (categoryFrequencies: any, columnName: any) => {
  // Check if columnName exists in the object
  if (categoryFrequencies && Object.keys(categoryFrequencies).length >0 && categoryFrequencies[columnName]) {
    if (categoryFrequencies[columnName].length > 0) {
      return true;
    }
  }
  return false;
};

