import { SliceCaseReducers } from '@reduxjs/toolkit/src/createSlice';

import { ModelVisualisationsProps } from '../../../types/reduxTypes/modelVisaulisations';

const createReducer = <T extends SliceCaseReducers<ModelVisualisationsProps>>(reducer: T) => ({ ...reducer });

const reducers = createReducer({
  setModelVisualisationsData(state, action) {
    state.modelVisualisationsData = action.payload;
  },
  setModelVisualisationsStatus(state, action) {
    state.modelVisualisationsStatus = action.payload;
  }
});

export default reducers;
