import { IconBrandAsana, IconDashboard, IconHome, IconReportSearch, IconDatabase, IconAtom } from '@tabler/icons';
import { ReactComponent as DatasetsBoxIcon } from './../assets/svg/datasets.svg';
import { ReactComponent as ProcessingBoxIcon } from './../assets/svg/preprocessing.svg';
import { ReactComponent as DeploymentBoxIcon } from './../assets/svg/deployment.svg';

// constant
const icons = {
  IconDashboard,
  IconHome,
  IconBrandAsana,
  IconReportSearch,
  IconDatabase,
  IconAtom
};

const dashboard = {
  id: 'default',
  title: '',
  type: 'group',
  children: [
    // {
    //   id: 'welcome',
    //   title: 'Welcome',
    //   type: 'item',
    //   url: '/',
    //   icon: icons.IconHome,
    //   breadcrumbs: false
    // },
    {
      id: 'resources',
      title: 'Datasets',
      type: 'item',
      url: '/resources',
      icon: DatasetsBoxIcon
    },
    {
      id: 'processing',
      title: 'Dataset Preprocessing',
      type: 'item',
      url: '/processing',
      icon: ProcessingBoxIcon
    },
    {
      id: 'DataModeling',
      title: 'Model building and deployment',
      type: 'collapse',
      icon: DeploymentBoxIcon,
      children: [
        // {
        //   id: 'DataSources',
        //   title: 'Data Sources',
        //   type: 'item',
        //   url: '/manager/datasource'
        // },
        {
          id: 'ModelBuilding',
          title: 'Model building and deployment',
          type: 'item',
          url: '/manager/model'
        },
        {
          id: 'ModelComparison',
          title: 'Result assessment',
          type: 'item',
          url: '/manager/analyze'
        }
      ]
    }
  ]
};

export default dashboard;
