import { SxProps } from '@mui/system';
import { Theme } from '@mui/material/styles';
import React from 'react';

export const containerStyles: React.CSSProperties = {
  // maxWidth: '100%',
  // height: '80vh', // Adjust height as needed
  overflow: 'hidden', // Enable vertical scroll for the table container
  margin: '20px 10px',
  padding: '20px 10px',
  backgroundColor: '#fff',
  border: '1px solid #fff',
  borderRadius: '8px'
};

export const tableStyles: React.CSSProperties = {
  overflowX: 'auto',
  height: '900px',
  whiteSpace: 'nowrap',
  width: '100%',
  border: '1px solid #B3B5B9',
  borderCollapse: 'collapse'
};
export const tableScrollStyles: React.CSSProperties = {
  width: '100%',
  height: '850px',
  overflow: 'auto'
};

export const topBlockStyles: React.CSSProperties = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: '10px'
};

export const nameAndColumns: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center'
};

export const backIconNameStyles: React.CSSProperties = {
  display: 'flex',
  alignItems: 'center'
};

export const backIconStyles: React.CSSProperties = {
  width: '16px',
  height: '16px',
  color: '#777777',
  marginRight: '10px',
  cursor: 'pointer'
};
export const fileNameStyles: React.CSSProperties = {
  color: '#3D4047',
  fontSize: '16px',
  fontWeight: '700',
  fontFamily: 'Circular Std Book'
};

export const columnsStyles: React.CSSProperties = {
  color: '#76787F',
  fontFamily: 'Circular Std Book',
  fontSize: '10px',
  fontWeight: '450'
};

export const columnsSpanStyles: React.CSSProperties = {
  marginLeft: '26px'
};

export const topBlockButtonsBlockStyles: React.CSSProperties = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
};

export const topBlockButtonsStyles: SxProps<Theme> = {
  '& .MuiSvgIcon-root': {
    width: '16px',
    height: '16px',
    color: '#334063'
  },
  '&:hover': {
    backgroundColor: '#fff', // background on hover
    color: '#334063', // Optional: text color on hover,
    boxShadow: 'none'
  },
  width: '100px',
  height: '24px',
  border: '1px solid #334063',
  borderRadius: '4px',
  fontFamily: 'Circular Std Book',
  fontSize: '12px',
  fontWeight: '450',
  color: '#334063',
  backgroundColor: '#fff',
  boxShadow: 'none'
};

export const topBlockFirstButtonStyles: SxProps<Theme> = {
  '& .MuiSvgIcon-root': {
    width: '16px',
    height: '16px',
    color: '#334063'
  },
  '&:hover': {
    backgroundColor: '#fff', // background on hover
    color: '#334063', // Optional: text color on hover,
    boxShadow: 'none'
  },
  width: '100px',
  height: '24px',
  border: '1px solid #334063',
  borderRadius: '4px',
  fontFamily: 'Circular Std Book',
  fontSize: '12px',
  fontWeight: '450',
  color: '#334063',
  backgroundColor: '#fff',
  marginRight: '10px',
  boxShadow: 'none'
};

export const buttonIconStyles: SxProps<Theme> = {
  '& .MuiSvgIcon-root': {
    width: '16px',
    height: '16px',
    color: '#334063'
  }
};

export const downloadButtonSlidingStyles: React.CSSProperties = {
  position: 'absolute',
  top: 0,
  left: '-100%',
  width: '100%',
  height: '100%',
  background: 'rgba(173, 216, 230, 0.5)', // Light blue with transparency
  animation: 'endlessSlide 1.5s linear infinite', // Endless sliding animation
  zIndex: 0
};

export const stickyStyles: React.CSSProperties = {
  position: 'sticky',
  zIndex: 2,
  backgroundColor: '#fff',
  boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.1)',
};

export const firstHeaderRowStyles: React.CSSProperties = {
  ...stickyStyles,
  top: 0,
  borderBottom: '1px solid #B3B5B9',
  backgroundColor: '#E4E9F2',
  fontFamily: 'Circular Std Book',
  fontSize: '12px',
  fontWeight: '450 !important',
  padding: '10px',
  borderRight: '1px solid #B3B5B9',
  color: '#3D4047'
};

export const firstHeaderInnerStyles: React.CSSProperties = {
  borderRight: '1px solid #B3B5B9',
  fontFamily: 'Circular Std Book',
  fontSize: '12px',
  fontWeight: '450',
  padding: '10px',
  color: '#3D4047'
};

export const secondHeaderInnerStyles: React.CSSProperties = {
  borderRight: '1px solid #B3B5B9',
  fontFamily: 'Circular Std Book',
  fontSize: '10px',
  fontWeight: '450',
  padding: '10px',
  color: '#3D4047'
};

export const secondHeaderSpanCategoricalStyles: React.CSSProperties = {
  border: '.5px solid #FBBC05',
  padding: '1px 2px 1px 4px',
  backgroundColor: '#fff5da',
  float: 'left'
};

export const secondHeaderSpanDateTimeStyles: React.CSSProperties = {
  border: '.5px solid #560884',
  padding: '1px 2px 1px 4px',
  backgroundColor: '#e6daed',
  float: 'left'
};

export const secondHeaderSpanNumericalStyles: React.CSSProperties = {
  border: '.5px solid #1698A1',
  padding: '1px 2px 1px 4px',
  backgroundColor: '#dcf0f1',
  float: 'left'
};

export const secondHeaderRowStyles: React.CSSProperties = {
  ...stickyStyles,
  borderBottom: '1px solid #B3B5B9',
  top: '40px',
  backgroundColor: '#fff'
};

export const thirdHeaderRowStyles: React.CSSProperties = {
  ...stickyStyles,
  borderBottom: '1px solid #B3B5B9',
  top: '80px',
  backgroundColor: '#fff'
};

export const rowIdCellStyles: React.CSSProperties = {
  fontFamily: 'Circular Std Book',
  fontSize: '10px',
  fontWeight: '450',
  padding: '4px 13px',
  color: '#3D4047',
  textAlign: 'center',
  borderRight: '1px solid #B3B5B9'
};

export const rowCellStyles: React.CSSProperties = {
  fontFamily: 'Circular Std Book',
  fontSize: '10px',
  fontWeight: '450',
  padding: '4px 10px',
  borderRight: '1px solid #B3B5B9',
  color: '#777777'
};

export const tableStylesSx: SxProps<Theme> = { width: '100%' };
export const circularProgressStyles: React.CSSProperties = { color: 'blue' };
export const loadingStyles: React.CSSProperties = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100vw',
  height: '100vh',
  marginTop: '-100px',
  marginLeft: '-480px'
};
export const sortingStyles: React.CSSProperties = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  cursor: 'pointer'
};
