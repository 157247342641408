import React, { memo, useEffect, useMemo, useState } from 'react';
import TableChartOutlinedIcon from '@mui/icons-material/TableChartOutlined';
import {
  Avatar,
  Card,
  CardContent,
  Grid,
  LinearProgress,
  linearProgressClasses,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography
} from '@mui/material';
import { styled, Theme, useTheme } from '@mui/material/styles';
import { dispatch, useAppSelector } from '../../store/hooks';
import { storageMiddleware, storageSelector } from '../../store/slices/storage';
import { ReactComponent as FloppyBoxIcon } from './../../../src/assets/svg/floppy.svg';

const BorderLinearProgress = styled(LinearProgress)(({ theme, value }: IBorderLineProgress) => ({
  height: 10,
  borderRadius: 30,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.dark.light : '#fff'
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor:
      (value > 66 && theme.palette.error.main) ||
      (value < 33 && theme.palette.success.main) ||
      theme.palette.warning.main
  }
}));

const CardStyle = styled(Card)(({ theme }) => ({
  background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.primary.light,
  marginBottom: '22px',
  overflow: 'hidden',
  position: 'relative',
  '&:after': {
    content: '""',
    position: 'absolute',
    width: '157px',
    height: '157px',
    background: theme.palette.mode === 'dark' ? theme.palette.dark.dark : theme.palette.primary[200],
    borderRadius: '50%',
    top: '-105px',
    right: '-96px'
  }
}));

interface IBorderLineProgress {
  theme: Theme;
  value: number;
}

interface LinearProgressWithLabelProps {
  value: number;
}

const LinearProgressWithLabel = ({ value, ...others }: LinearProgressWithLabelProps) => {
  const theme = useTheme();
  return (
    <Grid container direction="column" spacing={1} sx={{ mt: '-15px' }}>
      <Grid item>
        <Grid container justifyContent="space-between">
          <Grid item>
            <Typography
              variant="h6"
              sx={{
                color: theme.palette.primary[800]
              }}
            ></Typography>
          </Grid>
          <Grid item>
            <Typography variant="h6" color="inherit">{`${Math.round(value)}%`}</Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <BorderLinearProgress theme={theme} variant="determinate" value={value} {...others} />
      </Grid>
    </Grid>
  );
};

const MenuCard = () => {
  const theme = useTheme();
  const [storagePercent, setStoragePercent] = useState<any>(0);
  const [gb, setGb] = useState<any>(0);

  const { storageData, storageStatus } = useAppSelector(storageSelector.storageData);

  const bytesToGB = (bytes: number) => {
    const gb = bytes / (1024 * 1024 * 1024);
    return gb.toFixed(2); // Return GB rounded to 2 decimal places
  };

  const getStorageURL = `blob_file/get_user_storage_size`;

  useEffect(() => {
    dispatch(storageMiddleware.getStorage(getStorageURL));
    setGb(bytesToGB(storageData.storage));
    if (storageStatus === 200) {
      let a = (storageData.storage * 100) / 32212254720;
      setStoragePercent(a);
    }
  }, [storageData.storage]);

  const avatarSx = useMemo(
    () => ({
      ...theme.typography.commonAvatar,
      ...theme.typography.largeAvatar,
      color: theme.palette.primary.main,
      border: 'none',
      borderColor: theme.palette.primary.main,
      background: '#fff',
      marginRight: '12px'
    }),
    [theme.palette.primary.main, theme.typography.commonAvatar, theme.typography.largeAvatar]
  );

  return (
    <CardStyle>
      <CardContent sx={{ p: 2 }}>
        <List sx={{ p: 0, m: 0 }}>
          <ListItem alignItems="flex-start" disableGutters sx={{ p: 0 }}>
            <ListItemAvatar sx={{ mt: 0 }}>
              <Avatar style={{ width: '55px', height: '55px' }} variant="rounded" sx={avatarSx}>
                <FloppyBoxIcon style={{ width: '35px', height: '35px' }} fontSize="inherit" />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              sx={{ mt: 0 }}
              primary={
                <Typography
                  variant="subtitle1"
                  sx={{
                    color: theme.palette.mode === 'dark' ? theme.palette.dark.light : theme.palette.primary[800]
                  }}
                >
                  My Storage
                </Typography>
              }
              secondary={<Typography variant="caption"> {gb > 0 ? gb : 0} GB/30 GB</Typography>}
            />
          </ListItem>
        </List>
        <LinearProgressWithLabel value={storagePercent} />
      </CardContent>
    </CardStyle>
  );
};

export default memo(MenuCard);
