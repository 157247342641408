import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'store/store';

const selector = (state: RootState) => state.modelVisualisations;

const modelVisualisationsData = createSelector([selector], (state) => state);

export default {
  modelVisualisationsData
};
