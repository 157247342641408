import React, { useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { titleStyles } from './processingPage.styles';
import ProcessingInput from './processingInput/processingInput.component';
import ProcessingTable from './processingTable/processingTable.component';

const ProcessingPage = () => {
  const [processingInputData, setProcessingInputData] = useState<Object>({
    dataset: '',
    uploadedFileName: '',
    uploadedFileSize: 0
  });
  const [datasetOrUpload, setDatasetOrUpload] = useState<string>('');
  const [activeStep, setActiveStep] = useState<number>(1);

  return (
    <>
      <div style={titleStyles}>Dataset Preprocessing</div>
      {activeStep === 1 && (
        <ProcessingInput
          setDatasetOrUpload={setDatasetOrUpload}
          processingInputData={processingInputData}
          setProcessingInputData={setProcessingInputData}
          setActiveStep={setActiveStep}
        />
      )}
      {activeStep === 2 && (
        <ProcessingTable
          setActiveStep={setActiveStep}
          datasetOrUpload={datasetOrUpload}
          processingInputData={processingInputData}
        />
      )}
    </>
  );
};

export default ProcessingPage;
