import React from 'react';
import { SxProps } from '@mui/system';
import { Theme } from '@mui/material/styles';

export const containerStyles: React.CSSProperties = {
  alignItems: 'center',
  flexDirection: 'column',
  display: 'flex'
};

export const parentInfoStyles: React.CSSProperties = {
  alignSelf: 'flex-start'
};

export const childInfoStyles: React.CSSProperties = {
  // marginBottom: '10px'
};

export const labelStyles: React.CSSProperties = {
  color: '#76787F',
  fontFamily: 'Circular Std Book',
  fontSize: '14px',
  fontWeight: '700',
  lineHeight: '15.18px',
  paddingBottom: '20px'
};

export const inputStyles: React.CSSProperties = {
  display: 'flex',
  marginBottom: '20px',
  justifyContent: 'center',
  width: '100%'
};

export const autocompleteStyles: SxProps<Theme> = {
  width: '354px',
  height: '36px',
  marginRight: '10px',
  '& .MuiOutlinedInput-root': {
    padding: '0px',
    minHeight: '36px',
    '& fieldset': {
      border: '1px solid #76787F !important',
      borderColor: '#000',
      borderRadius: '4px'
    }
  },
  '& .MuiAutocomplete-listbox': {
    fontFamily: 'Circular Std Book !important'
  }
};

export const autocompletePlaceholderStyles: SxProps<Theme> = {
  '& .MuiInputBase-input::placeholder': {
    fontSize: '14px',
    fontFamily: 'Circular Std Book',
    color: '#777777',
    fontWeight: 450
  }
};

export const autocompleteLiStyles: React.CSSProperties = { fontFamily: 'Circular Std Book' };

export const autocompleteIconStyles: React.CSSProperties = {
  marginLeft: '10px',
  marginRight: '4px',
  width: '20px',
  height: '20px'
};