import React, { useState } from 'react';
import { Autocomplete, CircularProgress, TextField } from '@mui/material';
import { Search } from '@mui/icons-material';
import {
  autocompleteIconStyles,
  autocompleteLiStyles,
  autocompletePlaceholderStyles,
  autocompleteStyles, childInfoStyles,
  containerStyles,
  inputStyles,
  labelStyles, parentInfoStyles
} from './processingBiasReport.styles';
import { IProcessingBiasReport } from './processingBiasReport.models';
import ProcessingCartBar from '../processingChartBar/processingChartBar.component';
import ProcessingProgressBar from '../processingProgressBar/processingProgressBar.component';
import { IconInfoCircle } from '@tabler/icons';
import Tooltip from '@mui/material/Tooltip';

const ProcessingBiasReport: React.FC<IProcessingBiasReport> = ({
                                                                 modelType,
                                                                 columnName,
                                                                 biasReportChartData,
                                                                 biasReportCategoricalsData
                                                               }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState<string>(''); // Initial value is empty string
  const [categoricalInputValue, setCategoricalInputValue] = useState<string>(''); // Initial value is empty string
  const [secondInputValue, setSecondInputValue] = useState<string>('by frequency');

  const handleAutocompleteChange = (event: any, newValue: any) => {
    setInputValue(newValue?.value || '');
  };

  const handleSecondAutocompleteChange = (event: any, newValue: any) => {
    setSecondInputValue(newValue?.value || '');
  };
  const handleCategoricalAutocompleteChange = (event: any, newValue: any) => {
    setCategoricalInputValue(newValue?.value || '');
  };

  let keysArray: any = [];
  if (modelType === 'object') {
    // Check if biasReportChartData['categoricals'] and biasReportChartData['categoricals'][columnName] are not undefined
    if (biasReportChartData['categoricals'] && biasReportChartData['categoricals'][columnName]) {
      keysArray = Object.keys(biasReportChartData['categoricals'][columnName]);
    }
  } else {
    // Check if biasReportChartData['numerics'] and biasReportChartData['numerics'][columnName] are not undefined
    if (biasReportChartData['numerics'] && biasReportChartData['numerics'][columnName]) {
      keysArray = Object.keys(biasReportChartData['numerics'][columnName]);
    }
  }
  const secondInputOptions = ['by frequency', 'by relative frequency'];

  return (
    <div style={containerStyles}>
      <>
        {modelType === 'object' ? (
          <>
            <div style={inputStyles}>
              <Autocomplete
                options={keysArray.map((key: any) => ({ label: key, value: key }))} // Map keysArray to options
                onChange={handleCategoricalAutocompleteChange}
                value={
                  keysArray
                    .map((key: any) => ({ label: key, value: key })) // Map keysArray to match options format
                    .find((option: any) => option.value === categoricalInputValue) || null
                }
                loading={loading}
                noOptionsText={loading ? <CircularProgress size={24} /> : 'No options'}
                sx={autocompleteStyles}
                renderOption={(props, option) => (
                  <li {...props} style={autocompleteLiStyles}>
                    {option.label}
                  </li>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder='Search option'
                    label=''
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: (
                        <>
                          <Search style={autocompleteIconStyles} />
                          {params.InputProps.startAdornment}
                        </>
                      ),
                      style: autocompleteLiStyles
                    }}
                    sx={autocompletePlaceholderStyles}
                  />
                )}
                componentsProps={{
                  clearIndicator: {
                    onClick: () => setCategoricalInputValue('')
                  }
                }}
                disableClearable={!categoricalInputValue}
              />
            </div>
            {categoricalInputValue.length > 0 && (
              <>
                <div style={{ display: 'flex', alignSelf: 'self-start', marginBottom: '20px'}}>
                <div style={parentInfoStyles}>
                  <div style={childInfoStyles}>
                    <span style={{color: '#000'}}>Chi-Square
                      <Tooltip
                        title='Compares the observed and expected frequencies to test if two categorical variables come from the same distribution.'>
                        <span>
                        <IconInfoCircle size={14} style={{ color: '#000', fill: '#fff' }} />
                        </span>
                      </Tooltip>
                    </span>
                    <span> : {parseFloat(biasReportCategoricalsData[categoricalInputValue]?.chisquared).toFixed(5)}
                      <Tooltip
                        title='A p-value less than 0.05 indicates that the two categorical variables do not come from the same distribution, suggesting a significant association between them.'>
                        <span>
                          <IconInfoCircle size={14} style={{ color: '#000', fill: '#fff' }} />
                        </span>
                      </Tooltip>
                    </span>
                  </div>
                </div>
                <div style={{  marginLeft: '20px',display: 'flex', alignItems: 'center' }}>
                  <div style={{width:'26px', height: '13px', backgroundColor: 'rgb(153, 216, 222)'}}/>
                  <span>{biasReportCategoricalsData[categoricalInputValue].unique_values[0]}</span>
                  <div style={{marginLeft: '20px',width:'26px', height: '13px', backgroundColor: 'rgb(243, 220, 149)'}}/>
                  <span>{biasReportCategoricalsData[categoricalInputValue].unique_values[1]}</span>
                </div>
                </div>
                {Object.entries(biasReportCategoricalsData[categoricalInputValue].frequencies).map(([key, value]: [string, any]) => {
                  const { counts, percentage } = value;

                  // Pass both percentages and counts directly to the ProcessingProgressBar component
                  return (
                    <ProcessingProgressBar
                      key={key}
                      percentage={33}
                      percentageBoth={[Math.round(percentage[0] * 100), Math.round(percentage[1] * 100)]} // Pass both percentages
                      name={key}
                      counts={counts} // Pass counts as an array
                      mode={true}
                      bsCat={true}
                    />
                  );
                })}
              </>
            )}
          </>
        ) : (
          <>
            <div style={inputStyles}>
              <Autocomplete
                options={keysArray.map((key: any) => ({ label: key, value: key }))} // Map keysArray to options
                onChange={handleAutocompleteChange}
                value={
                  keysArray
                    .map((key: any) => ({ label: key, value: key })) // Map keysArray to match options format
                    .find((option: any) => option.value === inputValue) || null
                }
                loading={loading}
                noOptionsText={loading ? <CircularProgress size={24} /> : 'No options'}
                sx={autocompleteStyles}
                renderOption={(props, option) => (
                  <li {...props} style={autocompleteLiStyles}>
                    {option.label}
                  </li>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder='Search option'
                    label=''
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: (
                        <>
                          <Search style={autocompleteIconStyles} />
                          {params.InputProps.startAdornment}
                        </>
                      ),
                      style: autocompleteLiStyles
                    }}
                    sx={autocompletePlaceholderStyles}
                  />
                )}
                componentsProps={{
                  clearIndicator: {
                    onClick: () => setInputValue('')
                  }
                }}
                disableClearable={!inputValue}
              />

              {inputValue.length > 0 && (
                <Autocomplete
                  options={secondInputOptions.map((key) => ({ label: key, value: key }))} // Map keysArray to options
                  onChange={handleSecondAutocompleteChange}
                  value={
                    secondInputOptions
                      .map((key) => ({ label: key, value: key })) // Map keysArray to match options format
                      .find((option) => option.value === secondInputValue) || null
                  }
                  loading={loading}
                  noOptionsText={loading ? <CircularProgress size={24} /> : 'No options'}
                  sx={autocompleteStyles}
                  renderOption={(props, option) => (
                    <li {...props} style={autocompleteLiStyles}>
                      {option.label}
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder='Search option'
                      label=''
                      InputProps={{
                        ...params.InputProps,
                        startAdornment: (
                          <>
                            <Search style={autocompleteIconStyles} />
                            {params.InputProps.startAdornment}
                          </>
                        ),
                        style: autocompleteLiStyles
                      }}
                      sx={autocompletePlaceholderStyles}
                    />
                  )}
                  componentsProps={{
                    clearIndicator: {
                      onClick: () => setSecondInputValue('')
                    }
                  }}
                  disableClearable={!secondInputValue}
                />
              )}
            </div>
            {biasReportChartData && Object.keys(biasReportChartData).length > 0 && inputValue.length > 0 && secondInputValue.length > 0 && (
              <>
                <div style={parentInfoStyles}>
                  <div style={childInfoStyles}>
                    <span style={{color: '#000'}}>KS Test
                      <Tooltip
                        title='Kolmogorov–Smirnov Test compares the distributions of two samples.'>
                        <span>
                        <IconInfoCircle size={14} style={{ color: '#000', fill: '#fff' }} />
                        </span>
                      </Tooltip>
                    </span>
                      <span> : {biasReportChartData?.numerics?.[columnName]?.[inputValue].ks.toFixed(5)}
                        <Tooltip
                          title='A p-value less than 0.05 indicates a significant difference between the distributions of the two samples.'>
                        <span>
                          <IconInfoCircle size={14} style={{ color: '#000', fill: '#fff' }} />
                        </span>
                      </Tooltip>
                    </span>
                  </div>
                  <div style={childInfoStyles}>
                    <span style={{color: '#000'}}>Mann-Whitney U
                      <Tooltip
                        title='Tests for differences in medians across samples.'>
                        <span>
                        <IconInfoCircle size={14} style={{ color: '#000', fill: '#fff' }} />
                        </span>
                      </Tooltip>
                    </span>
                    <span> : {biasReportChartData?.numerics?.[columnName]?.[inputValue].mannwhitneyu.toFixed(5)}
                      <Tooltip
                        title='A p-value less than 0.05 suggests a significant difference in the medians across the groups.'>
                        <span>
                          <IconInfoCircle size={14} style={{ color: '#000', fill: '#fff' }} />
                        </span>
                      </Tooltip>
                    </span>
                  </div>
                  <div style={childInfoStyles}>
                    <span style={{color: '#000'}}>Levene's Test
                      <Tooltip
                        title='Tests the equality of variances across samples.'>
                        <span>
                        <IconInfoCircle size={14} style={{ color: '#000', fill: '#fff' }} />
                        </span>
                      </Tooltip>
                    </span>
                      <span> : {biasReportChartData?.numerics?.[columnName]?.[inputValue].levene.toFixed(5)}
                        <Tooltip
                          title='A p-value less than 0.05 indicates a significant difference in the variances across the groups.'>
                        <span>
                          <IconInfoCircle size={14} style={{ color: '#000', fill: '#fff' }} />
                        </span>
                      </Tooltip>
                    </span>
                  </div>
                </div>

                <ProcessingCartBar
                  biasReportChartData={
                    secondInputValue === 'by frequency'
                      ? biasReportChartData?.numerics?.[columnName]?.[inputValue]?.['histogram'] ?? []
                      : biasReportChartData?.numerics?.[columnName]?.[inputValue]?.['relative_histogram'] ?? []
                  }
                  label=''
                  open={true}
                />
              </>
            )}
          </>
        )}
      </>
    </div>

  );
};

export default ProcessingBiasReport;
