import * as Yup from 'yup';

export const validationSchema = Yup.object({
  dataset: Yup.mixed().required('A file is required').nullable()
});

export const initialValues = {
  dataset: '',
  uploadedFileName: '',
  uploadedFileSize: 0
};
