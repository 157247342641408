import React from 'react';
import { Route, Routes } from 'react-router-dom';

import ManagerPage from '../pages/ManagersPage';
import ResourcesPage from '../pages/ResourcesPage';
import NotFound from '../pages/notFound/notFound';
import ProcessingPage from '../pages/ProcessingPage/processingPage.component';

const Router = () => (
  <Routes>
    <Route path="/" element={<ResourcesPage />} />
    <Route path="/resources" element={<ResourcesPage />} />
    <Route path="/processing" element={<ProcessingPage />} />
    <Route path="/manager/:page" element={<ManagerPage />} />
    <Route path="/notfound" element={<NotFound />} />
  </Routes>
);

export default Router;
