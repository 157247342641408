import React, { useEffect, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Dialog,
  DialogTitle,
  IconButton,
  TextField,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Tooltip,
  CircularProgress,
  Button,
  TextareaAutosize,
  Tabs,
  Tab
} from '@mui/material';
import { dispatch, useAppSelector } from '../../store/hooks';
import { modalsMiddleware } from '../../store/slices/modals';
import { ModalName } from '../../types/modals';
import { MbConverter } from '../../helpers/MbConverter';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import RefreshIcon from '@mui/icons-material/Refresh';
import { makeStyles } from '@mui/styles';
import { viewUrlMiddleware, viewUrlSelector } from '../../store/slices/viewUrl';
import { removeLastExtension } from '../../helpers/removeExtension';
import { setViewUrlData, setViewUrlStatus } from '../../store/slices/viewUrl/middleware';

export interface UrlDialogProps {
  fileName: string;
  fileSize: number;
  shapValueWanted: boolean;
}

function TabPanel(props: any) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  };
}

const UrlDialog = ({ fileName, fileSize, shapValueWanted }: UrlDialogProps) => {
  const onModalClose = () => {
    dispatch(modalsMiddleware.closeModal(ModalName.urlDialog));
    dispatch(setViewUrlData({}));
    dispatch(setViewUrlStatus(0));
  };

  const [copied, setCopied] = useState(false);
  const [shapCopied, setShapCopied] = useState(false);
  const [jsonCopied, setJsonCopied] = useState(false);
  const [shapJsonCopied, setShapJsonCopied] = useState(false);
  const [apiResponse, setApiResponse] = useState<string>('');
  const [shapApiResponse, setShapApiResponse] = useState<string>('');
  const userId = localStorage.getItem('userId');

  const { viewUrlData, viewUrlStatus } = useAppSelector(viewUrlSelector.viewUrlData);

  const [currentDataIndex, setCurrentDataIndex] = useState(0);
  const [shapCurrentDataIndex, setShapCurrentDataIndex] = useState(0);

  const [currentData, setCurrentData] = useState<any>(
    viewUrlData && viewUrlData.payload_example && viewUrlData.payload_example[currentDataIndex]
  );
  const [shapCurrentData, setShapCurrentData] = useState<any>(
    viewUrlData && viewUrlData.payload_example && viewUrlData.payload_example[shapCurrentDataIndex]
  );
  const [jsonData, setJsonData] = useState('{}');
  const [editableContent, setEditableContent] = useState('');
  const [shapJsonData, setShapJsonData] = useState('{}');
  const [shapEditableContent, setShapEditableContent] = useState('');
  const [tabValue, setTabValue] = useState(0);

  const handleTabChange = (event: any, newValue: any) => {
    setTabValue(newValue);
  };

  const getViewUrl = `/model_deployment/view_url?model_name=${fileName}`;

  useEffect(() => {
    dispatch(viewUrlMiddleware.getViewUrl(getViewUrl));
  }, [dispatch, getViewUrl]); // Ensure useEffect is called once or any time getViewUrl changes

  // useEffect(() => {
  //   try {
  //     dispatch(viewUrlMiddleware.getViewUrl(getViewUrl));
  //   } catch (error) {
  //     console.error('Error fetching data:', error);
  //     onModalClose(); // Close modal if there's an error
  //   }
  // }, [dispatch, getViewUrl]); // Ensure useEffect is called once or any time getViewUrl changes

  // useEffect(() => {
  //   const fetchPromise = dispatch(viewUrlMiddleware.getViewUrl(getViewUrl))
  //     .then(() => {
  //       // Handle success if needed
  //     })
  //     .catch((error) => {
  //       console.error('Error fetching data:', error);
  //       onModalClose(); // Close modal if there's an error
  //     });
  //
  //   // Ensure the effect doesn't return the promise
  //   return () => {
  //     // Cleanup code if needed, like canceling ongoing requests
  //   };
  // }, [dispatch, getViewUrl, onModalClose]);

  // useEffect to watch for changes in viewUrlData
  useEffect(() => {
    // Ensure viewUrlData has the data we're interested in
    if (viewUrlData && viewUrlData.payload_example && viewUrlData.payload_example.length > 0) {
      const newCurrentData = viewUrlData.payload_example[currentDataIndex];
      setCurrentData(newCurrentData);
      const initialEditableContent = JSON.stringify(newCurrentData, null, 2).slice(1, -1);
      setEditableContent(initialEditableContent);
      setJsonData(`{\n${initialEditableContent}\n}`);
    }
  }, [viewUrlData, currentDataIndex]); // This useEffect depends on viewUrlData and currentDataIndex

  useEffect(() => {
    if (viewUrlData && viewUrlData.payload_example && viewUrlData.payload_example.length > 0) {
      const newCurrentData = viewUrlData.payload_example[shapCurrentDataIndex];
      setShapCurrentData(newCurrentData);
      const initialEditableContent = JSON.stringify(newCurrentData, null, 2).slice(1, -1);
      setEditableContent(initialEditableContent);
      setShapJsonData(`{\n${initialEditableContent}\n}`);
    }
  }, [viewUrlData, shapCurrentDataIndex]); // This useEffect depends on viewUrlData and currentDataIndex

  function containsOnlyCurlyBraces(inputString: any) {
    // Remove spaces
    let stringWithoutSpaces = inputString.replace(/\s/g, '');

    // Check if the string contains only '{}'
    if (/^[{}]*$/.test(stringWithoutSpaces)) {
      return true;
    }

    // Check if the string contains '{<spaces>}'
    if (/^{(\s)*}$/.test(stringWithoutSpaces)) {
      return true;
    }

    return false;
  }

  const handleChange = (e: any) => {
    const newValue = e.target.value;
    // Check if the changes respect the non-editability of the curly braces
    if (newValue.startsWith('{\n') && newValue.endsWith('\n}')) {
      setEditableContent(newValue.slice(2, -2));
      setJsonData(newValue);
    }
  };
  const handleShapChange = (e: any) => {
    const newValue = e.target.value;
    // Check if the changes respect the non-editability of the curly braces
    if (newValue.startsWith('{\n') && newValue.endsWith('\n}')) {
      setShapEditableContent(newValue.slice(2, -2));
      setShapJsonData(newValue);
    }
  };

  const handleApiCall = async () => {
    try {
      const response = await fetch(viewUrlData.model_url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: jsonData
      });

      if (response.ok) {
        const responseData = await response.json();
        // if (
        //   (responseData.hasOwnProperty('score') && Object.keys(responseData).length !== 0) ||
        //   (responseData[0].response.hasOwnProperty('predict') && Object.keys(responseData).length !== 0) ||
        //   (responseData[0].response.hasOwnProperty('probability') && Object.keys(responseData).length !== 0) ||
        //   (responseData[0].response.hasOwnProperty('prediction') && Object.keys(responseData).length !== 0)
        // ) {
        setApiResponse(JSON.stringify(responseData, null, 2)); // Update state with API response
        // } else {
        //   setApiResponse('The Request Body is not correct');
        // }
      } else {
        console.error('API Error:', response.statusText);
        setApiResponse('The Request Body is not correct');
      }
    } catch (err) {
      console.error('API Call Error:', err);
      setApiResponse('The Request Body is not correct');
    }
  };

  const handleShapApiCall = async () => {
    try {
      const response = await fetch(viewUrlData.shap_url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: shapJsonData
      });

      if (response.ok) {
        const responseData = await response.json();
        if (
          (responseData.hasOwnProperty('score') && Object.keys(responseData).length !== 0) ||
          (responseData[0].response.hasOwnProperty('predict') && Object.keys(responseData).length !== 0)
        ) {
          setShapApiResponse(JSON.stringify(responseData, null, 2)); // Update state with API response
        } else {
          setShapApiResponse('The Request Body is not correct');
        }
      } else {
        console.error('API Error:', response.statusText);
        setShapApiResponse('The Request Body is not correct');
      }
    } catch (err) {
      console.error('API Call Error:', err);
      setShapApiResponse('The Request Body is not correct');
    }
  };

  const handleCopyClick = async () => {
    try {
      await navigator.clipboard.writeText(viewUrlData.model_url);
      setCopied(true);
    } catch (err) {
      console.error('Unable to copy text: ', err);
    }
  };

  const handleShapCopyClick = async () => {
    try {
      await navigator.clipboard.writeText(viewUrlData.shap_url);
      setShapCopied(true);
    } catch (err) {
      console.error('Unable to copy text: ', err);
    }
  };

  const copyButton = document.getElementById('copyButton');
  const shapCopyButton = document.getElementById('shapCopyButton');

  if (copyButton) {
    copyButton.addEventListener('click', handleCopyClick);
  }
  if (shapCopyButton) {
    shapCopyButton.addEventListener('click', handleShapCopyClick);
  }

  const handleCopyJsonClick = async () => {
    try {
      await navigator.clipboard.writeText(jsonData);
      setJsonCopied(true);
    } catch (err) {
      console.error('Unable to copy text: ', err);
    }
  };

  const handleShapCopyJsonClick = async () => {
    try {
      await navigator.clipboard.writeText(shapJsonData);
      setShapJsonCopied(true);
    } catch (err) {
      console.error('Unable to copy text: ', err);
    }
  };

  const handleRefreshClick = () => {
    setCurrentDataIndex((prevIndex) => (prevIndex + 1) % (viewUrlData?.payload_example?.length || 1)); // ddddd
  };
  const handleShapRefreshClick = () => {
    setShapCurrentDataIndex((prevIndex) => (prevIndex + 1) % (viewUrlData?.payload_example?.length || 1)); // ddddd
  };

  const useStyles = makeStyles((theme: any) => ({
    noPadding: {
      padding: 0
    }
  }));
  const classes = useStyles();

  return (
    <Dialog
      classes={{ paper: classes.noPadding }}
      fullWidth
      maxWidth="lg"
      onClose={onModalClose}
      aria-labelledby="simple-dialog-title"
      open
    >
      <Box>
        <DialogTitle style={{ textAlign: 'center', position: 'relative' }} id="draggable-dialog-title">
          Model Url
          <IconButton
            onClick={onModalClose}
            sx={{
              position: 'absolute',
              right: 20,
              top: 10
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <Card>
          <CardHeader
            sx={{ textAlign: 'center' }}
            title={`${removeLastExtension(fileName)} / ${MbConverter(fileSize)}`}
          />
        </Card>

        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={tabValue} onChange={handleTabChange} aria-label="basic tabs example">
            <Tab style={{ color: '#000' }} label="Model Predict" {...a11yProps(0)} />
            {viewUrlData && viewUrlData.shap_url && viewUrlData.shap_url.length !== '' && (
              <Tab style={{ color: '#000' }} label="Shap Values" {...a11yProps(1)} />
            )}
          </Tabs>
        </Box>
        {viewUrlStatus === 200 ? (
          <>
            <TabPanel value={tabValue} index={0}>
              <CardContent sx={{ pt: 0 }}>
                <Table style={{ border: '2px solid #7f7f7f', borderRadius: '4px' }}>
                  <TableBody>
                    <TableRow style={{ backgroundColor: 'rgba(0, 0, 0, 0.04)' }}>
                      <TableCell style={{ borderBottom: 'none' }}>Url</TableCell>
                      <TableCell style={{ borderBottom: 'none' }}>
                        <TextField fullWidth value={viewUrlData.model_url} inputProps={{ type: 'text' }} disabled />
                      </TableCell>
                      <TableCell style={{ borderBottom: 'none' }}>
                        <Tooltip title="Copy">
                          <IconButton id="copyButton" onClick={handleCopyClick} aria-label="Copy">
                            <FileCopyIcon />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                    <TableRow style={{ backgroundColor: 'rgba(0, 0, 0, 0.04)' }}>
                      <TableCell style={{ borderBottom: 'none' }}>Request Body</TableCell>
                      <TableCell style={{ borderBottom: 'none' }}>
                        <textarea
                          value={jsonData}
                          onChange={handleChange}
                          rows={10}
                          cols={50}
                          style={{ borderRadius: '8px', width: '100%', marginBottom: '10px' }}
                        />
                      </TableCell>
                      <TableCell>
                        <Tooltip title="Copy">
                          <IconButton onClick={handleCopyJsonClick} aria-label="Copy">
                            <FileCopyIcon />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Refresh">
                          <IconButton onClick={handleRefreshClick} aria-label="Refresh">
                            <RefreshIcon />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
                {viewUrlStatus === 200 && (
                  <div style={{ marginTop: '20px', width: '250px' }}>
                    <Button
                      style={{
                        backgroundColor: containsOnlyCurlyBraces(jsonData) ? 'rgb(204, 204, 204)' : '#FB951A',
                        color: containsOnlyCurlyBraces(jsonData) ? 'rgb(102, 102, 102)' : '#fff'
                      }}
                      variant="contained"
                      onClick={handleApiCall}
                      fullWidth
                      disabled={containsOnlyCurlyBraces(jsonData)}
                    >
                      Make API Call
                    </Button>
                  </div>
                )}
                {Object.keys(apiResponse).length > 0 && (
                  <div>
                    <p style={{ color: '#000000', marginBottom: '0' }}>Response</p>
                    <TextareaAutosize
                      placeholder="API Response"
                      value={apiResponse}
                      readOnly
                      style={{ width: '100%', marginTop: '10px' }}
                    />
                  </div>
                )}
              </CardContent>
            </TabPanel>
            {viewUrlData && viewUrlData.shap_url && viewUrlData.shap_url.length !== '' && (
              <TabPanel value={tabValue} index={1}>
                <DialogTitle style={{ textAlign: 'center', position: 'relative' }} id="draggable-dialog-title">
                  Shap Values
                </DialogTitle>
                <CardContent sx={{ pt: 0 }}>
                  <Table style={{ border: '2px solid #7f7f7f', borderRadius: '4px' }}>
                    <TableBody>
                      <TableRow style={{ backgroundColor: 'rgba(0, 0, 0, 0.04)' }}>
                        <TableCell style={{ borderBottom: 'none' }}>Url</TableCell>
                        <TableCell style={{ borderBottom: 'none' }}>
                          <TextField fullWidth value={viewUrlData.shap_url} inputProps={{ type: 'text' }} disabled />
                        </TableCell>
                        <TableCell style={{ borderBottom: 'none' }}>
                          <Tooltip title="Copy">
                            <IconButton id="shapCopyButton" onClick={handleShapCopyClick} aria-label="Copy">
                              <FileCopyIcon />
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                      <TableRow style={{ backgroundColor: 'rgba(0, 0, 0, 0.04)' }}>
                        <TableCell style={{ borderBottom: 'none' }}>Request Body</TableCell>
                        <TableCell style={{ borderBottom: 'none' }}>
                          <textarea
                            value={shapJsonData}
                            onChange={handleShapChange}
                            rows={10}
                            cols={50}
                            style={{ borderRadius: '8px', width: '100%', marginBottom: '10px' }}
                          />
                        </TableCell>
                        <TableCell>
                          <Tooltip title="Copy">
                            <IconButton onClick={handleShapCopyJsonClick} aria-label="Copy">
                              <FileCopyIcon />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Refresh">
                            <IconButton onClick={handleShapRefreshClick} aria-label="Refresh">
                              <RefreshIcon />
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                  {viewUrlStatus === 200 && (
                    <div style={{ marginTop: '20px', width: '250px' }}>
                      <Button
                        style={{
                          backgroundColor: containsOnlyCurlyBraces(shapJsonData) ? 'rgb(204, 204, 204)' : '#FB951A',
                          color: containsOnlyCurlyBraces(shapJsonData) ? 'rgb(102, 102, 102)' : '#fff'
                        }}
                        variant="contained"
                        onClick={handleShapApiCall}
                        fullWidth
                        disabled={containsOnlyCurlyBraces(shapJsonData)}
                      >
                        Make API Call
                      </Button>
                    </div>
                  )}
                  {Object.keys(shapApiResponse).length > 0 && (
                    <div>
                      <TextareaAutosize
                        placeholder="API Response"
                        value={shapApiResponse}
                        readOnly
                        style={{ width: '100%', marginTop: '10px' }}
                      />
                    </div>
                  )}
                </CardContent>
              </TabPanel>
            )}
          </>
        ) : (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100vw',
              height: '100vh',
              marginTop: '-100px',
              marginLeft: '-480px'
            }}
          >
            <CircularProgress style={{ color: 'blue' }} />
          </div>
        )}
      </Box>
    </Dialog>
  );
};

export default UrlDialog;
