import API from '../../../manager/API';
import { dispatch } from '../../hooks';
import slice from './slice';

export const { setModelVisualisationsData, setModelVisualisationsStatus } = slice.actions;

const getModelVisualisations = (url: any) => async () => {
  try {
    const response = await API.modelVisualisations.getModelVisualisations(url);
    dispatch(setModelVisualisationsData(response.data));
    dispatch(setModelVisualisationsStatus(response.status));
    return { response, status: response.status, data: response.data };
  } catch (error) {}
};

export default {
  getModelVisualisations
};
