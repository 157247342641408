import { Axios } from '../axiosInstance';

const axiosInstance = Axios();

const modelVisualizationsManager = {
  getModelVisualisations(url: any) {
    return axiosInstance.v2.get(url);
  }
};

export default modelVisualizationsManager;
