import React, { useEffect, useState } from 'react';
import {
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material';
import { styled } from '@mui/material/styles';

import MainCard from '../MainCard';

// table columns
const columns = [
  { field: 'column_name', headerName: 'Column name' },
  { field: 'type', headerName: 'Type' },
  { field: 'na_values', headerName: 'NA values' },
  { field: 'mean_or_mode', headerName: 'Mean or Mode' },
  { field: 'max', headerName: 'Max' },
  { field: 'min', headerName: 'Min' },
  { field: 'unique_value', headerName: 'Unique values' }
];

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.primary[800],
    color: theme.palette.common.white,
    textAlign: 'center'
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    textAlign: 'center',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '200px' // set the maximum width here
  },
  '&:hover': {
    '&::after': {
      content: 'attr(title)',
      display: 'block',
      position: 'absolute',
      backgroundColor: '#333',
      color: '#fff',
      padding: '5px',
      zIndex: 1,
      left: '100%',
      top: '0',
      whiteSpace: 'normal',
      width: 'auto',
      minWidth: '100px',
      textAlign: 'center'
    }
  }
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover
  },
  // hide last border
  '&:last-of-type td, &:last-of-type th': {
    border: 0
  }
}));

const PreviewInfoTable = ({
  data,
  selectedColumn,
  setSelectedColumn,
  setSelectedColumnName,
  selectedColumnName,
  handleAccordionChange,
  setOpenAccordionIndex,
  setSelectedColumnNameCopy,
  selectedColumnNameCopy
}: any) => {
  // // const [selectedColumn, setSelectedColumn] = useState<any>([]);
  // const dinamycKey = Object.keys(data.preview_data);
  const headers = Object.keys(data).length > 0 ? Object.keys(data?.preview_data) : [];

  // Function to handle column click
  const handleColumnClick = (columnIndex: any, columnType: any, columnName: any, isTransform: any) => {
    const isSelected = selectedColumn.some((col: any) => col.index === columnIndex);

    if (isSelected) {
      setSelectedColumn((prevSelectedColumn: any) =>
        prevSelectedColumn.filter((col: any) => col.index !== columnIndex)
      );
      setSelectedColumnName((prevSelectedColumnName: any) =>
        prevSelectedColumnName.filter((col: any) => col !== columnName)
      );
      setSelectedColumnNameCopy(selectedColumnName);

      handleAccordionChange(-1);
    } else {
      setSelectedColumn((prevSelectedColumn: any) => [
        ...prevSelectedColumn,
        { index: columnIndex, type: columnType, isTransform: isTransform }
      ]);
      setSelectedColumnName((prevSelectedColumnName: any) => [...prevSelectedColumnName, columnName]);
    }
  };

  // Reset the selected column when the data changes
  useEffect(() => {
    setSelectedColumn([]);
    setSelectedColumnNameCopy(selectedColumnName);
    setSelectedColumnName([]);
    setOpenAccordionIndex(-1);
  }, [data]);

  const numberOfRows =
    data.preview_data && Object.keys(data.preview_data).length > 0
      ? Math.min(data.preview_data[Object.keys(data.preview_data)[0]].values.length, 50)
      : 0; // Ensure you don't exceed 50 rows and handle the case where there's no data.

  const rowIds = Array.from({ length: numberOfRows }, (_, index) => index + 1);

  return (
    <>
      {data.summary !== null && Object.keys(data).length > 0 ? (
        <MainCard content={false} sx={{ width: '100%' }}>
          <TableContainer sx={{ width: '100%', overflowY: 'scroll', height: '500px' }}>
            <Table
              style={{ overflowX: 'auto', whiteSpace: 'nowrap', height: '500px' }}
              sx={{ width: '100%' }}
              aria-label="customized table"
            >
              <TableHead>
                <TableRow>
                  {/* Add the "id" header */}
                  <StyledTableCell
                    key="id"
                    style={{ position: 'sticky', top: 0, zIndex: 1 }}
                    sx={{
                      backgroundColor: selectedColumn.some((col: any) => col.index === -1) ? 'red' : 'initial'
                    }}
                  />
                  {headers.map((header, index) => (
                    <StyledTableCell
                      key={header}
                      style={{ position: 'sticky', top: 0, zIndex: 1 }}
                      sx={{
                        cursor: 'pointer',
                        backgroundColor: selectedColumn.some((col: any) => col.index === index) ? 'red' : 'initial'
                      }}
                      onClick={() =>
                        handleColumnClick(
                          index,
                          data.preview_data[header].type,
                          header,
                          data.preview_data[header].is_transform
                        )
                      }
                    >
                      {header}
                    </StyledTableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {rowIds.map((rowId, rowIndex) => (
                  <StyledTableRow hover key={rowIndex}>
                    {/* Render the row ID */}
                    <StyledTableCell
                      key="id"
                      sx={{
                        backgroundColor:
                          selectedColumnName.length === 0
                            ? 'initial' // Set background color to 'initial' when selectedColumn.length is 0
                            : selectedColumn.some((col: any) => col.index === -1)
                            ? 'rgb(195, 205, 255)'
                            : 'initial'
                      }}
                    >
                      {rowId}
                    </StyledTableCell>
                    {headers.map((header, columnIndex) => (
                      <StyledTableCell
                        key={header}
                        sx={{
                          backgroundColor:
                            selectedColumnName.length === 0
                              ? 'initial' // Set background color to 'initial' when selectedColumn.length is 0
                              : selectedColumn.some((col: any) => col.index === columnIndex)
                              ? 'rgb(195, 205, 255)'
                              : 'initial'
                        }}
                      >
                        {data.preview_data[header].values[rowIndex]}
                      </StyledTableCell>
                    ))}
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </MainCard>
      ) : (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100vw',
            height: '100vh',
            marginTop: '-100px',
            marginLeft: '-480px'
          }}
        >
          <CircularProgress style={{ color: 'blue' }} />
        </div>
      )}
    </>
  );
};

export default PreviewInfoTable;
