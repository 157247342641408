import React, { useEffect } from 'react';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CloseIcon from '@mui/icons-material/Close';
import CropFreeIcon from '@mui/icons-material/CropFree';
import DataObjectIcon from '@mui/icons-material/DataObject';
import Filter1Icon from '@mui/icons-material/Filter1';
import {
  Avatar,
  Box,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Dialog,
  DialogTitle,
  IconButton,
  Typography
} from '@mui/material';

import { IProcessingDatasourceSummaryModal } from './processingDatasourceSummaryModal.models';
import InfoTable from './processingDatasourceSummaryTable/processingDatasourceSummaryTable.component';
import { datasourceSummaryIcons } from '../../../helpers/dashboardData';
import { removeLastExtension } from '../../../helpers/removeExtension';
import { MbConverter } from '../../../helpers/MbConverter';


type Icons = 'shape' | 'NA' | 'numeric' | 'object';

const getIconFromName = (iconName: Icons) => {
  switch (iconName) {
    case 'shape':
      return <CropFreeIcon style={{ color: 'white' }} fontSize="large" />;
    case 'NA':
      return <CheckBoxOutlineBlankIcon style={{ color: 'white' }} fontSize="large" />;
    case 'numeric':
      return <Filter1Icon style={{ color: 'white' }} fontSize="large" />;
    case 'object':
      return <DataObjectIcon style={{ color: 'white' }} fontSize="large" />;
    default:
      return '';
  }
};

const ProcessingDatasourceSummaryModal = ({ open, onClose, summaryData, summaryStatus }: IProcessingDatasourceSummaryModal) => {

  const updatedDatasourceSummaryIcons = datasourceSummaryIcons.map((icon:any, index:any) => {
    if (Object.keys(summaryData).length > 0) {
      if (summaryData.summary !== null) {
        if(summaryData?.summary?.title_summary[index]){
          const key = Object.keys(summaryData?.summary?.title_summary[index])[0];
          return {
            ...icon,
            info: summaryData?.summary?.title_summary[index][key]
          };
        }
      }
    }
  });

  return (
    <Dialog fullWidth maxWidth="lg" onClose={onClose} aria-labelledby="simple-dialog-title" open={open}>
      <Box>
        <DialogTitle style={{ textAlign: 'center', position: 'relative' }} id="draggable-dialog-title">
          Data Summary
          <IconButton
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 20,
              top: 10
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <Card>
          <CardHeader
            sx={{ textAlign: 'center' }}
            title={`${removeLastExtension(summaryData.filename)} / ${MbConverter(summaryData.file_size)}`}
          />
          {summaryStatus === 200 ? (
            <CardContent sx={{ pt: 0 }}>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', paddingX: '50px' }}>
                {summaryData.summary !== null &&
                Object.keys(summaryData).length > 0 &&
                updatedDatasourceSummaryIcons.length > 0 &&
                updatedDatasourceSummaryIcons.map((el: any) => {
                  const icon = getIconFromName(el?.icon);

                  return (
                    <>
                    {el && (
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                      <Avatar sx={{ bgcolor: el?.color, marginBottom: '8px', width: 56, height: 56 }}>{icon}</Avatar>
                      <Box display="block" textAlign="center">
                        <Typography fontSize="20px" fontWeight={800}>
                          {el?.title}
                        </Typography>
                        <Typography fontWeight={800}>{el?.info}</Typography>
                      </Box>
                    </Box>
                    )}
                    </>
                  );
                })}
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  marginTop: '30px',
                  padding: '8px',
                  width: '100%',
                  '& .MuiPaper-root': {
                    width: '100%'
                  }
                }}
              >
                <InfoTable summaryData={summaryData} />
              </Box>
            </CardContent>
          ) : (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                minWidth: '1100px',
                margin: '0',
                height: '100vh'
              }}
            >
              <CircularProgress style={{ color: 'blue' }} />
            </div>
          )}
        </Card>
      </Box>
    </Dialog>
  );
};

export default ProcessingDatasourceSummaryModal
