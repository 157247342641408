import {
  progressBarFilledPartModeTrueStyles,
  progressBarFilledPartStyles,
  progressBarLabelAndPercentageStyles, progressBarParentModeTrueBsCatFalseStyles,
  progressBarParentModeTrueStyles,
  progressBarParentStyles,
  progressBarPercentageLastSpanModeTrueStyles,
  progressBarPercentageLastSpanStyles,
  wholeProgressBarModeTrueStyles,
  wholeProgressBarStyles
} from './processingProgressBar.styles';
import React from 'react';
import { IProcessingProgressBar } from './processingProgressBar.models';

const ProcessingProgressBar: React.FC<IProcessingProgressBar> = ({
                                                                   categoricalCorrelation,
                                                                   counts,
                                                                   percentageBoth,
                                                                   percentage,
                                                                   name,
                                                                   mode,
                                                                   bsCat
                                                                 }) => {
  return (
    (bsCat && percentageBoth) || !bsCat ? (
      <div
        style={
          mode
            ? (bsCat
            ? progressBarParentModeTrueStyles
            : progressBarParentModeTrueBsCatFalseStyles)
            : progressBarParentStyles
        }
      >
        {bsCat && <span>{name}</span>}
        <div style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          width: '100%',
          marginLeft: bsCat ? '10px' : '0'
        }}>
          <div style={{ width: '100%' }}>
            <div style={progressBarLabelAndPercentageStyles}>
              {!bsCat && <span>{name}</span>}
              {!bsCat && (
                <span
                  style={
                    mode
                      ? percentage > 0
                      ? {
                        ...progressBarPercentageLastSpanModeTrueStyles,
                        border: '1px solid #c6ecc7',
                        backgroundColor: '#ddf4dd',
                        color: '#1b491c'
                      }
                      : {
                        ...progressBarPercentageLastSpanModeTrueStyles,
                        border: '1px solid #fac0b5',
                        backgroundColor: '#fcdbd4',
                        color: '#6f3024'
                      }
                      : progressBarPercentageLastSpanStyles
                  }
                >
                {categoricalCorrelation
                  ? `${percentage > 0 ? `+${percentage}` : `${percentage}`}`
                  : `${percentage > 0 ? `${percentage}%` : `${percentage}%`}`}
              </span>
              )}
            </div>
            {/* Progress Bar */}
            <div style={!mode ? wholeProgressBarStyles : wholeProgressBarModeTrueStyles}>
              <div
                style={
                  !mode
                    ? { ...progressBarFilledPartStyles, width: `${Math.abs(percentage)}%` }
                    : {
                      ...progressBarFilledPartStyles,
                      ...progressBarFilledPartModeTrueStyles,
                      height: bsCat ? '10px' : '4px',
                      backgroundColor: bsCat ? '#99d8de' : '#76C645',
                      width: `${bsCat ? Math.abs(percentageBoth[0]) : Math.abs(percentage)}%`
                    }
                }
                title={
                  !mode && !bsCat && counts
                    ? counts
                    : counts && counts[0] && counts[1]
                    ? counts[0]
                    : null
                }
              />
              {bsCat && <span style={{ marginLeft: '10px', visibility: 'visible' }}>{percentageBoth[0]}%</span>}
            </div>
          </div>

          {bsCat && (
            <div style={{ width: '100%' }}>
              <div style={progressBarLabelAndPercentageStyles}>
                {!bsCat && <span>{name}</span>}
              </div>
              {/* Progress Bar */}
              <div style={!mode ? wholeProgressBarStyles : wholeProgressBarModeTrueStyles}>
                <div
                  style={
                    !mode
                      ? { ...progressBarFilledPartStyles, width: `${Math.abs(percentage)}%` }
                      : {
                        ...progressBarFilledPartStyles,
                        ...progressBarFilledPartModeTrueStyles,
                        height: bsCat ? '10px' : '4px',
                        backgroundColor: bsCat ? '#f3dc95' : '#76C645',
                        width: `${Math.abs(percentageBoth[1])}%`
                      }
                  }
                  title={counts && counts[0] && counts[1] && counts[1]}
                />
                {bsCat && <span style={{ marginLeft: '10px', visibility: 'visible' }}>{percentageBoth[1]}%</span>}
              </div>
            </div>
          )}
        </div>
      </div>
    ) : null
  );

};

export default ProcessingProgressBar;
