import React, { useEffect, useState } from 'react';
import { IProcessingTable } from './processingTable.models';
import ProcessingCartBar from '../processingChartBar/processingChartBar.component';
import { Button, CircularProgress } from '@mui/material';
import ProcessingProgressBar from '../processingProgressBar/processingProgressBar.component';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import {
  backIconNameStyles,
  backIconStyles,
  circularProgressStyles,
  columnsSpanStyles,
  columnsStyles,
  containerStyles,
  downloadButtonSlidingStyles,
  fileNameStyles,
  firstHeaderInnerStyles,
  firstHeaderRowStyles,
  loadingStyles,
  nameAndColumns,
  rowCellStyles,
  rowIdCellStyles,
  secondHeaderInnerStyles,
  secondHeaderRowStyles,
  secondHeaderSpanCategoricalStyles,
  secondHeaderSpanDateTimeStyles,
  secondHeaderSpanNumericalStyles,
  sortingStyles,
  tableScrollStyles,
  tableStyles,
  thirdHeaderRowStyles,
  topBlockButtonsBlockStyles,
  topBlockButtonsStyles,
  topBlockFirstButtonStyles,
  topBlockStyles
} from './processingTable.styles';
import ProcessingTableSummary from '../processingTableSummary/processingTableSummary.component';
import API from '../../../manager/API';
import { removeLastExtension } from '../../../helpers/removeExtension';
import { ReactComponent as SummaryBoxIcon } from './../../../../src/assets/svg/summary.svg';
import { ReactComponent as DownloadBoxIcon } from './../../../../src/assets/svg/download.svg';
import { ReactComponent as SortingArrowUpIcon } from './../../../../src/assets/svg/arrowUp.svg';
import { ReactComponent as SortingArrowDownIcon } from './../../../../src/assets/svg/arrowDown.svg';
import { toast } from 'react-toastify';
import ProcessingDatasourceSummaryModal
  from '../processingDatasourseSummaryModal/processingDatasourceSummaryModal.component';
import { dispatch, useAppSelector } from '../../../store/hooks';
import { summaryMiddleware, summarySelector } from '../../../store/slices/summary';
import { resourcesSelector } from '../../../store/slices/resources';
import { modelVisualisationsMiddleware, modelVisualisationsSelector } from '../../../store/slices/modelVisualisations';
import ProcessingChartBar from '../processingChartBar/processingChartBar.component';
import {
  checkInCategoryFrequencies,
  checkInPureHistogram,
} from './processingTable.constants';

const ProcessingTable: React.FC<IProcessingTable> = ({ setActiveStep, datasetOrUpload, processingInputData }) => {
  const { summaryData, summaryStatus } = useAppSelector(summarySelector.summaryData);
  const [previewData, setPreviewData] = useState<any>(null);
  const [loading, setLoading] = useState(true);
  const [processingTableSummaryOpen, setProcessingTableSummaryOpen] = useState(false);
  const [processingDatasourceSummaryModalOpen, setProcessingDatasourceSummaryModalOpen] = useState<boolean>(false);
  const [columnName, setColumnName] = useState<string>('');
  const [sortConfig, setSortConfig] = useState<{ key: string; direction: 'asc' | 'desc' } | null>(null);
  const {
    modelVisualisationsData,
    modelVisualisationsStatus
  } = useAppSelector(modelVisualisationsSelector.modelVisualisationsData);
  const [activeTab, setActiveTab] = useState('correlations');
  const [showAll, setShowAll] = useState(false); // State to toggle "Show More/Less"

  const [downloading, setDownloading] = useState(false);
  const { resourcesData } = useAppSelector(resourcesSelector.resourcesData);

  const getPreviewUrl = `/data_operations/file_preview?filename=${
    datasetOrUpload === 'dataset' ? processingInputData.dataset : processingInputData.uploadedFileName
  }`;
  const getSummaryUrl = `/data_operations/data_summary?file_name=${
    datasetOrUpload === 'dataset' ? processingInputData.dataset : processingInputData.uploadedFileName
  }`;
  const getVisualisationsUrl = `/visualisations/get_visualisations?id=${previewData?.file_id}`;

  // Use optional chaining to safely access nested properties
  const headers = previewData?.preview_data ? Object.keys(previewData.preview_data) : [];
  const numberOfRows = previewData?.preview_data
    ? Math.min(previewData.preview_data[headers[0]]?.values.length || 0, 50)
    : 0;

  const headerClick = (header: string) => {
    setProcessingTableSummaryOpen(true);
    setColumnName(header);
  };

  const handleProcessingDatasourceSummaryModalOpen = () => {
    setProcessingDatasourceSummaryModalOpen(true); // Open the modal
  };

  const handleProcessingDatasourceSummaryModalClose = () => {
    setProcessingDatasourceSummaryModalOpen(false); // Close the modal
  };

  const downloadClick = async () => {
    const fileName = processingInputData.dataset;
    const downloadFileUrl = `/blob_file/download?file_name=${fileName}`;

    setDownloading(true); // Start the animation
    try {
      const downloadData = await API.download.downloadFile(downloadFileUrl);
      if (downloadData.status === 200) {
        toast.success(`Downloaded ${fileName}:`);
        setDownloading(false);
      } else {
        toast.error(removeLastExtension(downloadData.data.message));
        setDownloading(false);
      }

      const blob = new Blob([downloadData.data], { type: 'application/octet-stream' });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      toast.error(`Could not download ${fileName}:`);
      console.error(`An error occurred while downloading the file ${fileName}:`, error);
      setDownloading(false);
    } finally {
      setDownloading(false); // Stop the animation
    }
  };

  const closeProcessingTableSummary = () => {
    setProcessingTableSummaryOpen(false);
    setActiveTab('correlations');
    setShowAll(false)
  };

  // Fetch preview data asynchronously
  useEffect(() => {
    const fetchPreviewData = async () => {
      setLoading(true);
      try {
        const response = await API.preview.getPreview(getPreviewUrl);
        if (!response) {
          throw new Error('Failed to fetch preview data');
        }
        setPreviewData(response.data);
      } catch (error) {
        console.error('Error fetching preview data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchPreviewData();
    dispatch(summaryMiddleware.getSummary(getSummaryUrl));
  }, []);


  useEffect(() => {
    if (previewData && Object.keys(previewData).length > 0) {
      dispatch(modelVisualisationsMiddleware.getModelVisualisations(getVisualisationsUrl));
    }
  }, [previewData]);

  // Sorting function
  const handleSort = (header: string, direction: 'asc' | 'desc') => {
    setSortConfig({ key: header, direction });
  };

  function handleBack() {
    setActiveStep(1);
  }

  // Generate the sorted rows data based on the sort configuration
  const sortedRows = () => {
    if (!sortConfig || !previewData) return Array.from({ length: numberOfRows }, (_, index) => index);

    const { key, direction } = sortConfig;
    const rows = Array.from({ length: numberOfRows }, (_, index) => index);

    return rows.sort((a, b) => {
      const aValue = previewData.preview_data[key]?.values[a] || '';
      const bValue = previewData.preview_data[key]?.values[b] || '';

      if (aValue < bValue) return direction === 'asc' ? -1 : 1;
      if (aValue > bValue) return direction === 'asc' ? 1 : -1;
      return 0;
    });
  };

  const rowIds = sortedRows();

  return (
    <>
      {modelVisualisationsStatus !== 200 ? (
        <div style={loadingStyles}>
          <CircularProgress style={circularProgressStyles} />
        </div>
      ) : Object.keys(modelVisualisationsData).length > 0 ? (
        <>
          <div>
            <div style={containerStyles}>
              <div style={topBlockStyles}>
                <div style={nameAndColumns}>
                  <div style={backIconNameStyles}>
                    <ArrowBackIosNewIcon style={backIconStyles} onClick={handleBack} />
                    <span
                      style={fileNameStyles}>{removeLastExtension(datasetOrUpload === 'dataset' ? processingInputData.dataset : processingInputData.uploadedFileName)}</span>
                  </div>
                  <div style={columnsStyles}>
                    <span></span>
                    <span
                      style={columnsSpanStyles}>{modelVisualisationsData.shape.rows} rows, {modelVisualisationsData.shape.columns} columns</span>
                  </div>
                </div>
                <div style={topBlockButtonsBlockStyles}>
                  <Button
                    component='label'
                    variant='contained'
                    size='large'
                    sx={topBlockFirstButtonStyles}
                    onClick={handleProcessingDatasourceSummaryModalOpen}
                    startIcon={<SummaryBoxIcon width='16px' height='16px' color='#334063' />}
                  >
                    Summary
                  </Button>
                  <div>
                    <style>
                      {`
          @keyframes endlessSlide {
            0% {
              left: -100%;
            }
            100% {
              left: 100%;
            }
          }
        `}
                    </style>
                    <Button
                      component='label'
                      variant='contained'
                      size='large'
                      sx={{
                        ...topBlockButtonsStyles,
                        position: 'relative',
                        overflow: 'hidden'
                      }}
                      startIcon={<DownloadBoxIcon width='16px' height='16px' color='#334063' />}
                      onClick={downloadClick}
                    >
                      <span style={{ position: 'relative', zIndex: 1 }}>Download</span>
                      {downloading && <div style={downloadButtonSlidingStyles} />}
                    </Button>
                  </div>
                </div>
              </div>
              <div style={tableScrollStyles}>
                <table style={tableStyles}>
                  <thead>
                  <tr style={firstHeaderRowStyles}>
                    <th key='id' style={{ borderRight: '1px solid #B3B5B9'}}></th>
                    {headers.map((header) => (
                      <th key={header} style={{ ...firstHeaderInnerStyles}}>
                        <div style={sortingStyles}>
                          <span>{header}</span>
                          <div style={{ display: 'flex', flexDirection: 'column', marginLeft: '5px' }}>
                            {/* Up Arrow */}
                            <SortingArrowUpIcon
                              fontSize='medium'
                              style={{
                                cursor: 'pointer',
                                stroke:
                                  sortConfig?.key === header && sortConfig.direction === 'asc'
                                    ? '#007BFF' // Blue when active
                                    : '#B3B5B9', // Gray otherwise
                                transform:
                                  sortConfig?.key === header && sortConfig.direction === 'asc'
                                    ? 'scale(1.7)'
                                    : 'scale(1)', // Slightly larger if active
                                transition: 'fill 0.3s ease, transform 0.3s ease'
                              }}
                              onClick={() => handleSort(header, 'asc')}
                            />
                            <SortingArrowDownIcon
                              fontSize='medium'
                              style={{
                                cursor: 'pointer',
                                stroke:
                                  sortConfig?.key === header && sortConfig.direction === 'desc'
                                    ? '#007BFF' // Blue when active
                                    : '#B3B5B9', // Gray otherwise
                                transform:
                                  sortConfig?.key === header && sortConfig.direction === 'desc'
                                    ? 'scale(1.7)'
                                    : 'scale(1)', // Slightly larger if active
                                transition: 'fill 0.3s ease, transform 0.3s ease'
                              }}
                              onClick={() => handleSort(header, 'desc')}
                            />
                          </div>
                        </div>
                      </th>
                    ))}
                  </tr>
                  <tr style={secondHeaderRowStyles}>
                    <th key='id' style={{ borderRight: ' 1px solid #B3B5B9', padding: '8px'}}></th>
                    {headers.map((header) => (
                      <th key={header} style={{ ...secondHeaderInnerStyles, minWidth: '320px', maxWidth: '320px' }}>
                          <span
                            style={
                              previewData.preview_data[header]?.type === 'object'
                                ? secondHeaderSpanCategoricalStyles : previewData.preview_data[header]?.type === 'datetime64[ns]' ? secondHeaderSpanDateTimeStyles
                                : secondHeaderSpanNumericalStyles
                            }
                          >
                            {previewData.preview_data[header]?.type === 'object' ? 'Categorical' : previewData.preview_data[header]?.type === 'datetime64[ns]' ? 'Datetime' : 'Numeric'}
                          </span>
                      </th>
                    ))}
                  </tr>
                  <tr style={thirdHeaderRowStyles}>
                    <th key='id' style={{ borderRight: '1px solid #B3B5B9', padding: '8px', maxWidth: '320px' }}></th>


                    {headers.map((header) => {
                        let content = null;
                        let isClickable = true; // Flag to determine if the header should be clickable

                        if (modelVisualisationsData && Object.keys(modelVisualisationsData).length > 0) {

                          if (previewData?.preview_data[header]?.type === 'object') {
                            if (!checkInCategoryFrequencies(modelVisualisationsData?.category_frequency, header)) {
                              const categoryData = Object.entries(
                                modelVisualisationsData?.category_frequency?.[header] as Record<string, any> || {}
                              );
                              const firstTwo = categoryData.slice(0, 3);
                              const remaining = categoryData.slice(3);
                              const othersPercentage = remaining.reduce((sum, [, value]) => sum + value.percentage, 0);
                              const othersCount = remaining.reduce((sum, [, value]) => sum + value.count, 0);

                              content = (
                                <>
                                  <style>
                                    {`
                .progress-bar-others {
                  opacity: 0.6;
                }
              `}
                                  </style>
                                  {firstTwo.map(([key, value]) => (
                                    <ProcessingProgressBar
                                      key={key}
                                      percentage={parseFloat((value.percentage * 100).toFixed(3))}
                                      name={key}
                                      counts={value.count}
                                      mode={false}
                                      bsCat={false}
                                    />
                                  ))}
                                  {remaining.length > 0 && (
                                    <div style={{ opacity: 0.6 }}>
                                      <ProcessingProgressBar
                                        key='others'
                                        percentage={parseFloat((othersPercentage * 100).toFixed(3))}
                                        counts={othersCount}
                                        name='Others'
                                        mode={false}
                                        bsCat={false}
                                      />
                                    </div>
                                  )}
                                </>
                              );
                            } else {
                              content = (
                                <>
                                  <span>{modelVisualisationsData?.category_frequency[header]}</span>
                                </>
                              );
                              isClickable = false; // Disable clickability
                            }
                          }
                          if (previewData?.preview_data[header]?.type !== 'object' && previewData?.preview_data[header]?.type !== 'datetime64[ns]') {
                            if (!checkInPureHistogram(modelVisualisationsData?.pure_histogram, header)) {
                              content = (
                                <ProcessingChartBar
                                  defaultChartBarData={modelVisualisationsData?.pure_histogram[header]}
                                  label=''
                                  open={true}
                                />
                              );
                            } else {
                              content = (
                                <>
                                  <span>{modelVisualisationsData?.pure_histogram[header].message_instead}</span>
                                </>
                              );
                              isClickable = false; // Disable clickability
                            }
                          }
                          if (previewData?.preview_data[header]?.type === 'datetime64[ns]') {
                            content = (
                              <>
                                <span>It is dateTime column</span>
                              </>
                            );
                            isClickable = false; // Disable clickability
                          }

                          return (
                            <th
                              key={header}
                              onClick={() => isClickable && headerClick(header)} // Only trigger click if it's clickable
                              style={{
                                borderRight: '1px solid #B3B5B9',
                                padding: '8px',
                                width: '320px',
                                cursor: isClickable ? 'pointer' : 'not-allowed' // Change cursor if not clickable
                              }}
                            >
                              {content}
                            </th>
                          );
                        }
                      }
                    )
                    }


                  </tr>
                  </thead>
                  <tbody style={{ position: 'relative', zIndex: '1' }}>
                  {rowIds.map((rowIndex) => (
                    <tr key={rowIndex} style={{ borderBottom: '1px solid #B3B5B9' }}>
                      <td key='id' style={{ ...rowIdCellStyles}}>
                        {rowIndex + 1}
                      </td>
                      {headers.map((header) => (
                        <td key={header} style={{ ...rowCellStyles }}>
                          {previewData.preview_data[header]?.values[rowIndex] ?? 'N/A'}
                        </td>
                      ))}
                    </tr>
                  ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <ProcessingTableSummary
            summaryData={summaryData?.summary?.data_summary}
            shapeData={modelVisualisationsData?.shape}
            showAll={showAll}
            setShowAll={setShowAll}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            histogramChartBarData={modelVisualisationsData?.color_histogram}
            defaultChartBarData={modelVisualisationsData?.pure_histogram[columnName]}
            biasReportChartData={modelVisualisationsData?.bias_report}
            triangularChartBarData={modelVisualisationsData?.correlations.categoricals}
            numericCorrelationsData={columnName.length > 0 && modelVisualisationsData?.correlations?.numericals[columnName]}
            headerCategoryData={columnName.length > 0 && modelVisualisationsData?.category_frequency?.[columnName]}
            biasReportCategoricalsData={columnName.length > 0 && modelVisualisationsData?.bias_report?.categoricals[columnName]}
            label=''
            open={processingTableSummaryOpen}
            onClose={closeProcessingTableSummary}
            columnName={columnName}
            modelType={previewData && previewData.preview_data[columnName]?.type}
          />
          <ProcessingDatasourceSummaryModal
            open={processingDatasourceSummaryModalOpen}
            onClose={handleProcessingDatasourceSummaryModalClose}
            summaryData={summaryData}
            summaryStatus={summaryStatus}
          />
        </>
      ) : (
        <div>No preview data available</div>
      )}
    </>
  );
};

export default ProcessingTable;
