import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartOptions
} from 'chart.js';
import { HeatmapDataPoint, HeatmapSeries, IProcessingChartBar } from './processingChartBar.models';  // Import only the type
import ReactApexChart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';  // Import ApexOptions type from apexcharts

// Register necessary components
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const ProcessingChartBar: React.FC<IProcessingChartBar> = ({
                                                             inputValue,
                                                             defaultChartBarData,
                                                             histogramChartBarData,
                                                             biasReportChartData,
                                                             triangularChartBarData,
                                                             label,
                                                             open,
  modelType,
  activeTab
                                                           }) => {
  const [frequencies, setFrequencies] = useState(
    open && defaultChartBarData ? defaultChartBarData?.frequencies : Array(defaultChartBarData?.frequencies.length).fill(0)
  );

  useEffect(() => {
    if (open) {
      setFrequencies(defaultChartBarData?.frequencies); // Set original data when open
    } else {
      setFrequencies(Array(defaultChartBarData?.frequencies.length).fill(0)); // Set data to zeros when closed
    }
  }, [defaultChartBarData]);

  let data;

  const hasInputValue = inputValue && inputValue.length > 0;
  let barOptions: ChartOptions<'bar'>;  // For Chart.js (Bar chart options)
  let apexOptions: ApexOptions;  // For ReactApexChart (heatmap options)

  const ColorBar = () => (
    <div style={{ display: "flex", alignItems: "center", marginLeft: "10px", height: "100%" }}>
      {/* Color bar */}
      <div
        style={{
          height: "350px", // Match the height of the heatmap
          width: "20px",
          background: "linear-gradient(to top, #FF0000 0%, #FFC0CB 5%, #FFC0CB 100%)",
          border: "1px solid #000",
        }}
      />
      {/* Labels */}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          height: "350px", // Match the height of the color bar
          marginLeft: "5px",
        }}
      >
        <span style={{ fontSize: "12px" }}>1</span>
        <span style={{ fontSize: "12px" }}>0.75</span>
        <span style={{ fontSize: "12px" }}>0.5</span>
        <span style={{ fontSize: "12px" }}>0.25</span>
        <span style={{ fontSize: "12px" }}>0</span>
      </div>
    </div>
  );

  if (hasInputValue) {
    const frequencies = histogramChartBarData?.histogram.frequencies;

    // Generate labels for the bins
    const labels = frequencies?.map(
      (_: any, i: any) =>
        `${(histogramChartBarData?.histogram.start + i * histogramChartBarData?.histogram.binWidth).toFixed(2)} - ${(
          histogramChartBarData?.histogram.start +
          (i + 1) * histogramChartBarData?.histogram.binWidth
        ).toFixed(2)}`
    );

    // Number of segments per bar (all nested arrays have the same length)
    const segmentCount = frequencies[0].length;

    // Create datasets for each segment dynamically
    const datasets = Array.from({ length: segmentCount }).map((_, index) => ({
      label: histogramChartBarData?.ordered_categories[index],
      data: frequencies.map((f: number[]) => f[index]), // Use the index-th value for the dataset
      backgroundColor: `rgba(${75 + index * 50}, ${192 - index * 30}, ${192 + index * 20}, 0.6)`,
      borderColor: `rgba(${75 + index * 50}, ${192 - index * 30}, ${192 + index * 20}, 1)`,
      borderWidth: 1
    }));

    data = {
      labels,
      datasets
    };

    barOptions = {
      responsive: true,
      plugins: {
        legend: {
          position: 'top'
        },
        tooltip: {
          callbacks: {
            // Display the frequency and color square together on the first line
            label: function(tooltipItem) {
              const index = tooltipItem.dataIndex;
              const startBin = defaultChartBarData.start + index * defaultChartBarData.bin_width;
              const endBin = startBin + defaultChartBarData.bin_width;
              const frequency = tooltipItem.raw;

              // Format the range as [start, end)
              const range = `[${startBin.toFixed(2)}, ${endBin.toFixed(2)})`;

              // Return the frequency (and color square) as part of the label
              return `${frequency}`;
            },
            // Add the bin range on the second line
            title: function(tooltipItem) {
              const index = tooltipItem[0].dataIndex;
              const startBin = defaultChartBarData.start + index * defaultChartBarData.bin_width;
              const endBin = startBin + defaultChartBarData.bin_width;

              // Format the bin range
              const range = `[${startBin.toFixed(2)}, ${endBin.toFixed(2)})`;

              // Return the range for the title
              return range;
            }
          }
        },
        title: {
          display: true,
          text: 'Dynamic Histogram with Stacked Bars'
        }
      },
      scales: {
        x: {
          stacked: true,
          title: {
            display: true
          },
          ticks: {
            autoSkip: false,
            callback: (value, index, ticks) => {
              // Display the first and last bin ranges dynamically
              if (index === 0) {
                const startBin = defaultChartBarData.start;
                const endBin = startBin + defaultChartBarData.bin_width;
                return `[${startBin.toFixed(2)}, ${endBin.toFixed(2)})`;
              } else if (index === ticks.length - 1) {
                const lastBinStart = defaultChartBarData.start + (ticks.length - 1) * defaultChartBarData.bin_width;
                const lastBinEnd = lastBinStart + defaultChartBarData.bin_width;
                return `[${lastBinStart.toFixed(2)}, ${lastBinEnd.toFixed(2)})`;
              }
              return ''; // Leave other labels blank
            },
            maxRotation: 0, // Prevent rotation of tick labels
            minRotation: 0
          }
        },
        y: {
          stacked: true,
          title: {
            display: true
          },
          beginAtZero: true
        }
      },
      animation: {
        duration: 2000,
        easing: 'easeOutCubic'
      }
    };

  } else {
    // Default dataset when inputValue is absent
    const labels = defaultChartBarData?.frequencies.map((_: any, index: any) =>
      (defaultChartBarData.start + index * defaultChartBarData.bin_width).toFixed(2)
    );

    data = {
      labels,
      datasets: [
        {
          label: label,
          data: frequencies,
          backgroundColor: '#76C645'
        }
      ]
    };

    barOptions = {
      scales: {
        x: {
          title: {
            display: true
          },
          ticks: {
            autoSkip: false,
            callback: (value, index, ticks) => {
              if (index === 0) {
                const startBin = defaultChartBarData.start;
                const endBin = startBin + defaultChartBarData.bin_width;
                return `[${startBin.toFixed(2)}, ${endBin.toFixed(2)})`;
              } else if (index === ticks.length - 1) {
                const lastBinStart = defaultChartBarData.start + (ticks.length - 1) * defaultChartBarData.bin_width;
                const lastBinEnd = lastBinStart + defaultChartBarData.bin_width;
                return `[${lastBinStart.toFixed(2)}, ${lastBinEnd.toFixed(2)})`;
              }
              return ''; // Leave other labels blank
            },
            maxRotation: 0,
            minRotation: 0
          }
        },
        y: {
          title: {
            display: true
          },
          beginAtZero: true
        }
      },
      plugins: {
        legend: {
          display: false
        },
        tooltip: {
          callbacks: {
            // Display the frequency and color square together on the first line
            label: function (tooltipItem) {
              const index = tooltipItem.dataIndex;
              const startBin = defaultChartBarData.start + index * defaultChartBarData.bin_width;
              const endBin = startBin + defaultChartBarData.bin_width;
              const frequency = tooltipItem.raw;
              // Format the range as [start, end)
              const range = `[${startBin.toFixed(2)}, ${endBin.toFixed(2)})`;
              // Return the frequency (and color square) as part of the label
              return `${frequency}`;
            },
            // Add the bin range on the second line
            title: function (tooltipItem) {
              const index = tooltipItem[0].dataIndex;
              const startBin = defaultChartBarData.start + index * defaultChartBarData.bin_width;
              const endBin = startBin + defaultChartBarData.bin_width;
              // Format the bin range
              const range = `[${startBin.toFixed(2)}, ${endBin.toFixed(2)})`;
              // Return the range for the title
              return range;
            }
          }
        }
      },
      animation: {
        duration: 2000,
        easing: 'easeOutCubic'
      }
    };

  }

  // Restoring biasReportChartData handling
  if (biasReportChartData && Object.keys(biasReportChartData).length > 0) {
    const datasets: any = [];
    const labels: any = [];
    const colors = ['#99d8de', '#f3dc95'];

    // Generate datasets for A and B
    Object.entries(biasReportChartData)?.forEach(([key, chartData]: [string, any], datasetIndex) => {
      const { start, bin_width, frequencies } = chartData;

      // Generate labels for bins (ensures all bins are included across datasets)
      if (labels.length === 0) {
        const maxBins = Math.max(
          ...Object.values(biasReportChartData as Record<string, { frequencies: number[] }>)
            .map(({ frequencies }) => frequencies.length)
        );
        for (let i = 0; i < maxBins; i++) {
          const binStart = start + i * bin_width;
          const binEnd = binStart + bin_width;
          labels.push(`${binStart.toFixed(2)} - ${binEnd.toFixed(2)}`);
        }
      }

      // Add dataset for each key
      datasets.push({
        label: key,
        data: frequencies.concat(new Array(labels.length - frequencies.length).fill(0)), // Ensure alignment with labels
        backgroundColor: colors[datasetIndex % colors.length], // Single color per dataset
        borderColor: colors[datasetIndex % colors.length],
        borderWidth: 1,
      });
    });

    // Update data for the chart
    data = {
      labels,
      datasets,
    };

    // Chart options with tooltip integration
    barOptions = {
      responsive: true,
      plugins: {
        legend: {
          position: 'top',
        },
        title: {
          display: true,
        },
        tooltip: {
          callbacks: {
            // Display the frequency and color square together on the first line
            label: function(tooltipItem: any) {
              const index = tooltipItem.dataIndex;
              const datasetIndex = tooltipItem.datasetIndex;
              const chartData = biasReportChartData[Object.keys(biasReportChartData)[datasetIndex]];
              const { start, bin_width } = chartData;
              const startBin = start + index * bin_width;
              const endBin = startBin + bin_width;
              const frequency = tooltipItem.raw;

              // Return the frequency (and color square) as part of the label
              return `${frequency}`;
            },
            // Add the bin range on the second line
            title: function(tooltipItem: any) {
              const index = tooltipItem[0].dataIndex;
              const datasetIndex = tooltipItem[0].datasetIndex;
              const chartData = biasReportChartData[Object.keys(biasReportChartData)[datasetIndex]];
              const { start, bin_width } = chartData;
              const startBin = start + index * bin_width;
              const endBin = startBin + bin_width;

              // Format the bin range
              const range = `[${startBin.toFixed(2)}, ${endBin.toFixed(2)})`;

              // Return the range for the title
              return range;
            }
          }
        }
      },
      scales: {
        x: {
          stacked: false,
          title: {
            display: true,
          },
          ticks: {
            autoSkip: false,
            maxRotation: 0,
            minRotation: 0,
            callback: function (value, index, values) {
              const datasetKeys = Object.keys(biasReportChartData);
              const binLabels:any = [];

              datasetKeys.forEach((key) => {
                const chartData = biasReportChartData[key];
                const { start, bin_width, frequencies } = chartData;
                const totalBins = frequencies.length;

                // Add the first bin range for this dataset
                if (index === 0) {
                  const startBin = start;
                  const endBin = start + bin_width;
                  binLabels.push(`[${startBin.toFixed(2)}, ${endBin.toFixed(2)})`);
                }

                // Add the last bin range for this dataset
                if (index === values.length - 1) { // Use totalBins to handle all bins
                  const lastBinStart = start + (totalBins - 1) * bin_width;
                  const lastBinEnd = lastBinStart + bin_width;
                  binLabels.push(`[${lastBinStart.toFixed(2)}, ${lastBinEnd.toFixed(2)})`);
                }
              });

              // Return the compiled bin labels for the first and last ranges
              if (binLabels.length > 0) {
                return binLabels.join(', '); // Combine ranges for all datasets
              }

              return ''; // Hide other ticks
            },
          },
        },
        y: {
          stacked: false,
          title: {
            display: true,
          },
          beginAtZero: true,
        },
      },



      animation: {
        duration: 2000,
        easing: 'easeOutCubic',
      },
    };
  }



  if (modelType==='object' && activeTab==='correlations' && triangularChartBarData && Object.keys(triangularChartBarData).length > 0) {
    const processRawData = (
      data: Record<string, Record<string, number>>
    ): HeatmapSeries[] => {
      const categories = Object.keys(data); // Extract category names
      const series: HeatmapSeries[] = [];

      for (let i = 0; i < categories.length; i++) {
        const rowData: HeatmapDataPoint[] = [];
        for (let j = 0; j <= i; j++) {
          const value = data[categories[i]][categories[j]] || 0;
          rowData.push({
            x: categories[j],
            y: value,
          });
        }
        series.push({ name: categories[i], data: rowData });
      }

      return series.reverse(); // Reverse the series to simulate a reversed y-axis
    };

    const series: HeatmapSeries[] = processRawData(triangularChartBarData);

    apexOptions = {
      chart: {
        type: "heatmap",
        height: 350,
        toolbar: {
          show: false, // Disable the toolbar
        },
        background: "transparent", // Remove any background color
      },
      responsive: [
        {
          breakpoint: 1000, // Example: for screen width less than 1000px
          options: {
            chart: {
              width: "100%", // Adjust chart width
            },
          },
        },
        {
          breakpoint: 600, // Another breakpoint for small screens
          options: {
            chart: {
              width: "100%",
            },
          },
        },
      ],
      plotOptions: {
        heatmap: {
          shadeIntensity: 0.1,
          colorScale: {
            ranges: [
              { from: 0, to: 0.05, color: "#FF0000" },
              { from: 0.06, to: 1, color: "#FFC0CB" },
            ],
          },
        },
      },
      dataLabels: {
        enabled: true, // Enable the display of data values
        style: {
          colors: ["#000"], // Set the text color for the values
          fontSize: "10px", // Adjust the font size for better visibility
        },
        formatter: function (value) {
          // If the value is an array, take the first value (or compute the average, etc.)
          if (Array.isArray(value)) {
            value = value[0]; // Or you can compute the average if needed: value = value.reduce((a, b) => a + b) / value.length;
          }

          // Ensure that we are working with a valid number
          const numValue = typeof value === 'number' ? value : parseFloat(value);

          return !isNaN(numValue) ? numValue.toFixed(2) : value; // Format to 1 decimal place or return the original value if it's not a valid number
        },
      },
      xaxis: {
        labels: {
          show: true,
          rotate: -45,
          hideOverlappingLabels: false,
          style: {
            fontSize: "12px",
          },
        },
        tickPlacement: "on",
        axisBorder: {
          show: true, // Enable the bottom border for the x-axis
          color: "#000", // Set the color for the border
          offsetY: 0, // Align the border to the bottom
        },
        axisTicks: {
          show: false, // Disable ticks for the x-axis
        },
      },
      yaxis: {
        labels: {
          show: true,
          style: {
            fontSize: "12px",
          },
        },
        axisBorder: {
          show: true, // Enable the left border for the y-axis
          color: "#000", // Set the color for the border
          offsetX: 0, // Align the border to the left
        },
        axisTicks: {
          show: false, // Disable ticks for the y-axis
        },
      },
      grid: {
        show: false, // Completely remove grid lines
      },
      legend: {
        show: false, // Disable the legend
      },
    };

    return (
      <div style={{ width: '100%' }}>
        {modelType==='object' && activeTab==='correlations' && triangularChartBarData && Object.keys(triangularChartBarData).length > 0 ? (
          <div
            style={{
              display: "flex",
              alignItems: "center", // Align the heatmap and color bar vertically
              justifyContent: "space-between",
              width: "100%", // Full width
            }}
          >
            <div style={{ flex: 1 }}>
              <ReactApexChart options={apexOptions} series={series} type="heatmap" height={350} />
            </div>
            <ColorBar />
          </div>
        ) : (
          <Bar style={{ width: '300px' }} data={data} options={barOptions} />
        )}
      </div>
    );
  }

  return (
    <Bar style={{ width: '300px' }} data={data} options={barOptions} />
  );
};

export default ProcessingChartBar;
