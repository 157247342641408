import React from 'react';
import { SxProps } from '@mui/system';
import { Theme } from '@mui/material/styles';

export const sidebarWidth = '600px';

export const titleBoxStyles: SxProps<Theme> = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: '20px'
};

export const titleStyles: React.CSSProperties = {
  fontSize: '16px',
  fontWeight: '700',
  color: '#3D4047',
  paddingTop: '20px',
  paddingLeft: '20px',
  fontFamily: 'Circular Std Book'
};

export const closeIconStyles: SxProps<Theme> = {
  position: 'absolute',
  width: '19px',
  height: '19px',
  color: '#76787F',
  right: '19px',
  top: '19px'
};
export const wholeInfoStyles: React.CSSProperties = {
  display: 'flex',
  border: '1px solid #B3B5B9',
  margin: '0 30px',
  borderLeft: 'none',
  borderRight: 'none'
};
export const blockStyles: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  borderRight: '1px solid #B3B5B9',
  width: '100%',
  padding: '7px 0'
};
export const blockLastStyles: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  padding: '7px 0'
};
export const textStyles: React.CSSProperties = {
  fontWeight: '450',
  fontSize: '14px',
  fontFamily: 'Circular Std Book',
  color: '#777777'
};

export const descriptionStyles: React.CSSProperties = {
  fontWeight: '450',
  fontSize: '14px',
  fontFamily: 'Circular Std Book',
  color: '#000',
  margin: '0px 20px 40px 20px',
  textAlign: 'center'
};

export const numberStyles: React.CSSProperties = {
  color: '#3D4047',
  fontWeight: '450',
  fontSize: '14px',
  fontFamily: 'Circular Std Book'
};

export const chartTitleStyles: React.CSSProperties = {
  marginTop: '10px',
  fontWeight: '700',
  fontSize: '14px',
  fontFamily: 'Circular Std Book',
  color: '#3D4047',
  paddingLeft: '20px'
};

export const chartStyles: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  padding: '20px 40px'
};

export const borderBottomStyles: React.CSSProperties = {
  margin: '0px 30px',
  borderBottom: '1px solid #B3B5B9'
};

export const tabsParentStyles: React.CSSProperties = {
  display: 'flex',
  justifyContent: 'center',
  marginTop: '20px',
  marginBottom: '20px'
};

export const tabsContentStyles: React.CSSProperties = { padding: '16px' };
