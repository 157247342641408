import React from 'react';

export const progressBarParentStyles: React.CSSProperties = { width: '100%' };

export const progressBarParentModeTrueStyles: React.CSSProperties = {
  width: '100%',
  position: 'relative',
  marginBottom: '10px',
  display: 'flex',
  alignItems: 'center',
  borderBottom: '1px solid rgb(179, 181, 185)',
  paddingBottom: '10px'
};
export const progressBarParentModeTrueBsCatFalseStyles: React.CSSProperties = {
  width: '100%',
  position: 'relative',
  marginBottom: '10px',
  display: 'flex',
  alignItems: 'center',
  paddingBottom: '10px'
};

export const progressBarLabelAndPercentageStyles: React.CSSProperties = {
  display: 'flex',
  justifyContent: 'space-between',
  marginBottom: '5px',
  fontFamily: 'Circular Std Book',
  fontSize: '12px',
  fontStyle: 'italic',
  fontWeight: '450',
  color: '#777777'
};

export const progressBarPercentageLastSpanStyles: React.CSSProperties = { position: 'relative' };

export const progressBarPercentageLastSpanModeTrueStyles: React.CSSProperties = {
  position: 'absolute',
  right: '0px',
  top: '12px',
  borderRadius: '14px',
  padding: '2px 20px',
  width: '70px',
  height: '25px'
};

export const wholeProgressBarStyles: React.CSSProperties = {
  width: '100%',
  height: '8px',
  backgroundColor: '#e0e0e0',
  borderRadius: '1px',
  overflow: 'hidden',
  position: 'relative'
};

export const wholeProgressBarModeTrueStyles: React.CSSProperties = {
  visibility: 'hidden',
  display: 'flex',
  alignItems: 'center'
};

export const progressBarFilledPartStyles: React.CSSProperties = {
  height: '100%',
  backgroundColor: '#76C645',
  transition: 'width 0.3s ease'
};

export const progressBarFilledPartModeTrueStyles: React.CSSProperties = {
  visibility: 'visible',
  // height: '4px'
};
